<template>
  <div class="mt-3 m-3 relative w-full mx-auto">
    <div class="phone-input-container">
      <MazPhoneNumberInput
        v-model="phoneNumber"
        v-model:country-code="countryCode"
        show-code-on-list
        :preferred-countries="['EG', 'SA', 'AE', 'QA', 'SD']"
        :ignored-countries="['AC']"
        @update="handleUpdate"
        orientation="row"
        class="phone-input"
        color="#0B0C0E"
        :custom-countries-list="translatedCountries"
        :translations="{
          countrySelector: {
            placeholder: 'رمز الدولة',
            error: 'اختر الدولة',
            searchPlaceholder: 'البحث',
          },
          phoneInput: {
            placeholder: 'رقم الهاتف ',
            example: 'مثال:',
          },
        }"
        noExample="false"
      />
      <div class="  w-full mx-auto my-3">
          <button
            @click.prevent="loginWithPhone"
            class="inline-flex justify-center items-center gap-2 w-full bg-[#9d2890] text-gray-100 rounded-md py-3"
          >
            <span v-show="!loaded"> إرسال رمز التحقق</span>
            <div role="status" v-show="loaded">
              <btn-loading />
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
    </div>
  </div>
</template>

<script>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
import { HTTP } from "@/http-common";
import { translatedCountries, USERLOGINID } from "@/constants";
import BtnLoading from './svg/BtnLoading.vue';

export default {
  components: {
    MazPhoneNumberInput,
    BtnLoading,
  },
  data() {
    return {
      color: { gray: { name: "gray", hex: "#111827" } },
      countryCode: "EG",
      phoneNumber: null,
      // Set the default countryCode based on the browser's language setting
      results: null,
      toast: useToast(),
      loaded: false,
      language: "ar", // Set Arabic language for placeholders and UI
      translatedPlaceholders: {
        phoneNumber: "رقم الهاتف", // Translate placeholders here
        countryCode: "رمز الدولة",
      },
      translatedCountries: translatedCountries,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["authViaAction"]),

    handleUpdate(event) {
      this.results = event;
    },
    async loginWithPhone() {
      if (this.results.e164) {
        this.loaded = true;
        window.localStorage.setItem(USERLOGINID, this.results.e164);
        // send phone number and via method which is phone in this case 
        await this.authViaAction(this.results.e164,"phone").then((response) => {
          if (response.data.authVia.success) {
            this.toast.success("تم إرسال رمز التأكيد");
            this.$router.push({ name: "auth.otp.phone" });
            this.loaded = false;
          } else {
            this.toast.error("حدث خطأ ما، حاول مرة أخرى");
            this.loaded = false;
          }
        });
        this.loaded = false;
      }
    },
  },
  async created() {
    await HTTP.get("https://ipapi.co/json")
      .then((res) => {
        this.countryCode = res.data.country;
      })
      .catch((error) => {
        this.countryCode = "AE";
        console.log(error);
      });
  },
};
</script>

<style scoped></style>
