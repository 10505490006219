<template>
  <!-- component -->

  <div class="md:mx-auto">
    <HomeLayout :data="false"></HomeLayout>
    <!-- start of video show-->
    <div class="md:container mx-auto" v-if="assetData">
      <!-- This padding-top percentage gives us a 16:9 aspect ratio -->
      <template v-if="assetData.video_type == 'iframe'">
        <div v-html="unescapedEmbeddedCode"></div>
      </template>
      <template v-else>
        <div>
          <video-player :assetData="assetData" :next="next" />
        </div>
      </template>
    </div>
    <!-- end of video show-->

    <!-- start of video info-->
    <div class="md:mr-12 mr-4" v-if="assetData && assetData.serie != null">
      <video-info :serie="assetData.serie" />
    </div>
    <!-- end of video info-->
    <!-- start of video-->

    <div class="flex justify-end border-b-1 mt-10 md:mr-12 mr-4">
      <div>
        <h6 class="text-2xl font-bold text-white -mb-2">كل الحلقات</h6>
        <div class="my-1 border-color border border-b-2"></div>
      </div>
    </div>
    <!-- this just a new test -->
    <div
      class="mt-4 md:mr-12 mr-6"
      dir="rtl"
      v-if="assetData && assetData.serie != null"
    >
      <SpinnerView :loading="loading" />
      <card-swiper
        v-if="loaded"
        :items="sortedd"
        :className="className"
        :image="assetData.image"
        :currentPlaying="assetData.id"
      />
      <div class="text-center text-whtie text-lg text white" v-if="!assetData">
        لا توجد حلقات
      </div>
    </div>

    <!-- end of video-->

    <!-- end of programs-->
  </div>
</template>
<script>
import VideoInfo from "../../components/VideoInfo.vue";

import HomeLayout from "@/components/HomeLayout.vue";
import CardSwiper from "@/components/cards/CardSwiper.vue";
import { useEposidesStore } from "@/stores/eposides";
import { mapActions, mapState } from "pinia";
import SpinnerView from "@/components/SpinnerView.vue";
import {isSubscribed } from "@/constants";
import VideoPlayer from "@/components/videojs/VideoPlayer.vue";

export default {
  components: { HomeLayout, CardSwiper, SpinnerView, VideoInfo, VideoPlayer },

  data() {
    return {
      loaded: false,
      loading: false,
      sortedItems: [],
      previous: null,
      next: null,
      className: "eposides",
      s3: "https://d1p7gwkjs434q8.cloudfront.net/eposides/",
      option: {
        url: "",
        poster: "",
      },
      style: { width: "100%", height: "100%", margin: "-22px 2px 0px 2px " },
    };
  },
  computed: {
    ...mapState(useEposidesStore, ["assetData"]),
    sortedd() {
     

      return this.assetData?.serie?.eposides?.slice().sort((a, b) => {
        return -(b.order - a.order);
      });
    },
    unescapedEmbeddedCode() {
      // Remove sequences of backslashes
      return this.assetData.embedded_code.replace(/\\+/g, "");
    },
  },
  methods: {
    ...mapActions(useEposidesStore, ["fetchAssets"]),
    getInstance(art) {
      console.log(art);
    },
   
    sortAndMove() {
      if(this.assetData==null) {
        this.$router.push({ name: "home" });
      } 
      // Separate the item with the specified ID from the rest
      const itemToMoveToFront = this.assetData.serie.eposides.find(
        (item) => item.id === this.assetData.id
      );
      const remainingItems = this.assetData.serie.eposides.filter(
        (item) => item.id !== this.assetData.id
      );
      const previous = this.assetData.serie.eposides.find(
        (item) => item.order === itemToMoveToFront.order - 1
      );
      const next = this.assetData.serie.eposides.find(
        (item) => item.order === itemToMoveToFront.order + 1
      );
      this.next = next;

      this.previous = previous;

      // Sort the remaining items by their IDs
      remainingItems.sort((a, b) => a.id - b.id);

      // Place the item with the specified ID at the front
      const sortedItems = [itemToMoveToFront, ...remainingItems];

      this.sortedItems = sortedItems;
    },
  },
  async created() {
    this.loading = true;
    try {
      await this.fetchAssets(this.$route.params.id);
      this.loaded = true;
      this.loading = false;
    } catch (error) {
      console.error("Failed to grab  data", error);
    }

    this.option.url = this.assetData?.video_url;
    this.sortAndMove();
    /* eslint-disable */
    this.option.poster = `${this.s3}${this.assetData?.image}`;
    let subbed = localStorage.getItem(isSubscribed);

    if(this.assetData==null){
      return this.$router.push({ name: "home" });
    }
   
  },
};
</script>

<style scoped></style>
