

<template>
  <!-- component -->

  <div class=" md:mx-auto mb-4" v-if="serieData">
    <HomeLayout :data="true">
      <trailler-view :asset="serieData" :firstEposide="firstEposide"  />
    </HomeLayout>

   
    
    <div
        v-show=" loaded"
        class=" flex flex-wrap  my-3  md:mx-12 "
        dir="rtl"
      >
      
        <horizental-view v-for="(item,index) in sortedd" :key="item.id" :item="item" :index="index" :image="serieData.eposides.image" />
    </div>
 
    <SpinnerView :loading="loading" />
   
  </div>
</template>
<script>
import HomeLayout from "@/components/HomeLayout.vue";
import TraillerView from "@/components/cards/TraillerView.vue";
import { mapActions, mapState } from "pinia";
import SpinnerView from "@/components/SpinnerView.vue";
import { useSerieStore } from "@/stores/serie";
import HorizentalView from '@/components/cards/HorizentalView.vue';

export default {
  components: {
    
    HomeLayout,
    TraillerView,
    SpinnerView,
    HorizentalView,
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
 
  computed: {
    ...mapState(useSerieStore, ["firstEposide","serieData","getEposides"]),
    sortedd(){
    
    return this.serieData.eposides?.slice().sort((a,b)=> {
   return -(b.order - a.order)
    });
    
    }
  
  },
  methods: {
    ...mapActions(useSerieStore, ["ShowSerieData"]),
  },
  async created() {
    this.loading = true;
   this.loaded=false;
    try {
    
    await this.ShowSerieData(this.$route.params.id);
   
      this.loaded = true;
      this.loading = false;
    } catch (error) {
      console.error("Failed to grab portal data", error);
      this.loading = false;
      this.loaded=true;
    }
  
  },
};
</script>

<style>
</style>

