<template>
  <div class="rounded-lg h-full w-full relative">
    <router-link :to="`/brows/live/${live.id}`">
      <img
        ref="image"
        class="rounded-lg object-cover md:h-80 h-56 w-40 md:w-80"
        :src="defaultImage"
        :data-src="getImage(live)"
        width="100"
        height="100"
        :alt="live.title"
      />
    </router-link>
    <h5 class="m-2 text-lg font-bold text-center tracking-tight text-white">
      {{ live.host_name }}
    </h5>

    <div class="absolute top-2 right-2 m-1 space-between h-7">
      <div
        class="align-baseline nowrap rounded text-center mt-2 mr-2 py-0.5 text-sm px-1 inline font-normal bg-red-600 md:text-md text-white"
      >
        بث مباشر
      </div>
    </div>
  </div>
</template>

<script>
import { S3_BASE_URL } from "@/constants";

export default {
  props: ["live"],
  data() {
    return {
      s3: S3_BASE_URL,
      defaultImage: require("@/assets/placeholder.webp"),
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust as needed
    });

    this.observer.observe(this.$refs.image);
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src; // Add this line to set the src attribute
          this.observer.unobserve(img);
        }
      });
    },
    getImage(live) {
      if (live.cover) {
        return `${this.s3}lives/${live.cover}`;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "default.webp");
      }
    },
  },
};
</script>

<style></style>
