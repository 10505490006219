export const USER = 'user-data';
export const AUTHTOKEN = 'user-token';
export const AUTHKey = 'auth-key';
export const ISACTIVESTRIPEUSER = 'stripe-user-v1'
export const S3_BASE_URL = "https://d1p7gwkjs434q8.cloudfront.net/"
export const ISACTIVE = "isActive"
export const isSubscribed = "isSubscribed"
export const SUDANI_BASE_URL = "https://sudani.nasna.tv"
export const ZAIN_BASE_URL = "https://zain.nasna.tv"
export const USERLOGINID = "user-login-id"// the id could be phone number|email
export const translatedCountries= {
    EG: "مصر",
    SA: "المملكة العربية السعودية",
    AE: "الإمارات العربية المتحدة",
    QA: "قطر",
    SD: "السودان",
    FR: "فرنسا",
    BE: "بلجيكا",
    DE: "ألمانيا",
    US: "الولايات المتحدة الأمريكية",
    IT: "إيطاليا",
    ES: "إسبانيا",
    IN: "الهند",
    PK: "باكستان",
    TR: "تركيا",
    CN: "الصين",
    JP: "اليابان",
    RU: "روسيا",
    BR: "البرازيل",
    AR: "الأرجنتين",
    MX: "المكسيك",
    ID: "إندونيسيا",
    NG: "نيجيريا",
    ZA: "جنوب أفريقيا",
    GB: "المملكة المتحدة",
    CA: "كندا",
    AU: "أستراليا",
    NZ: "نيوزيلندا",
    SE: "السويد",
    NO: "النرويج",
    DK: "الدنمارك",
    FI: "فنلندا",
    CH: "سويسرا",
    NL: "هولندا",
    AT: "النمسا",
    PL: "بولندا",
    GR: "اليونان",
    PT: "البرتغال",
    TH: "تايلاند",
    MY: "ماليزيا",
    SG: "سنغافورة",
    PH: "الفلبين",
    VN: "فيتنام",
    KR: "كوريا الجنوبية",
    LY: "ليبيا",
    MA: "المغرب",
    DZ: "الجزائر",
    TN: "تونس",
    OM: "عمان",
    KW: "الكويت",
    BH: "البحرين",
    JO: "الأردن",
    LB: "لبنان",
    SY: "سوريا",
    IQ: "العراق",
    YE: "اليمن",
    PS: "فلسطين",
    IL: "إسرائيل",
    CY: "قبرص",
    MT: "مالطا",
    IS: "أيسلندا",
    HU: "المجر",
    CZ: "التشيك",
    SK: "سلوفاكيا",
    UA: "أوكرانيا",
    BY: "بيلاروسيا",
    KZ: "كازاخستان",
    UZ: "أوزبكستان",
    AF: "أفغانستان",
    BD: "بنغلاديش",
    LK: "سريلانكا",
    NP: "نيبال",
    MM: "ميانمار",
    KE: "كينيا",
    UG: "أوغندا",
    ET: "إثيوبيا",
    GH: "غانا",
    CI: "ساحل العاج",
    SN: "السنغال",
    CL: "تشيلي",
    CO: "كولومبيا",
    VE: "فنزويلا",
    PE: "بيرو",
    CR: "كوستاريكا",
    PA: "بنما",
    UY: "أوروجواي",
    DO: "جمهورية الدومينيكان",
    HT: "هايتي",
    CU: "كوبا",
    JM: "جامايكا",
    TT: "ترينيداد وتوباغو",
    IR: "إيران",
    AZ: "أذربيجان",
    KG: "قرغيزستان",
    TJ: "طاجيكستان",
    MN: "منغوليا",
    KH: "كمبوديا",
    LA: "لاوس",
    MZ: "موزمبيق",
    ZM: "زامبيا",
    MG: "مدغشقر",
    BW: "بوتسوانا",
    NA: "ناميبيا",
  }
  