<template>
  <div class="flex justify-center rounded-lg mx-2 mt-2 md:mt-6">
    <div class="rounded-md md:w-5/12">
      <div
        class="container mx-auto px-4 p-1 bg-basic-dark  shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center"
      >
        <auth-carsouel class="rounded-md" />
        <div class="">
          <h5
            dir="rtl"
            class="my-3 text-md text-lg text-white font-bold font-Almarai text-center"
          >
            اشترك الان واستمتع بمشاهدة البرامج والقنوات بلا حدود
          </h5>
          <!-- <div  class="border border-b-2 border-pink-500  mx-2 mx-auto mt-1 w-36"></div> -->

          <div class="md:w-96 mx-auto">
            <div class="m-3 relative" dir="rtl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 absolute right-3 top-2 rotate-230"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                stroke-width="1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>

              <input
                type="text"
                class="bg-gray-900 border border-color focus:ring-pink-500 focus:border-pink-500 text-gray-900 text-sm rounded-sm w-full"
                placeholder="0118457785"
              />
            </div>

            <div class="flex justify-center my-6 mr-6">
              <button
                type="submit"
                class=" focus:ring-0 focus:outline-none focus:ring-pink-300 rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center btn-color w-btn text-white mr-6 md:mr-0 cursor-pointer absloute z-20"
                @click="subscribe"
              >
                اشترك الان
              </button>
            </div>
          </div>

          <div class="text-white hidden" dir="rtl">
            <p
              class="text-center font-small m-2 font-Almarai"
              style="font-size: 11px; line-height: 15px"
            >
              قيمة الاشتراك اليومي لكل المقاطع والبرامج 80 جنيه فقط شاملة
              الضريبة تجدد تلقائيا عبر شبكة سوداني
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthCarsouel from "@/components/shared/AuthCarsouel.vue";
import { HTTP } from "@/http-common";
export default {
  components: {
    AuthCarsouel,
  },
  methods: {
    async subscribe() {
      try {
        await HTTP.get("subscriptions/url").then((response) =>
          console.log(response)
        );
      } catch (error) {
        this.$router.push({ name: "failed" });
        console.error("Failed subscribe", error);
      }
    },
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}
.xxsm {
  width: 255px;
}
.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-basic {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}
.font-small {
  font-size: 16px;
}
</style>
