<template>
  <div :class="data ? 'md:h-[80dvh] h-[28rem] overflow-hidden':''" class="relative  ">
      <DesktopMenu/>
      <slot />
 
 </div>
 

</template>

<script>
import DesktopMenu from './DesktopMenu.vue'

export default {
  components: { DesktopMenu },
  props:['data']

}
</script>

<style>

</style>