import NotFound from '@/views/main/NotFound.vue'

export const notFoundRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      auth: false
    }
  }
]