<template>
  <div class="mb-4">
    <div>
      <HomeLayout :data="true">
       <swiper-slider/>
      </HomeLayout>
    </div>
    <div v-if="loaded">
      <div class="flex justify-end m-3 mr-2">
        <div class="my-3 mr-8">
          <h6 class="text-whtie font-bold text-lg md:text-3xl text-white">
            {{ genresSeriesData.title }}
          </h6>
          <div class="border border-color border-b-2"></div>
        </div>
      </div>
      <div class=" mx-auto px-4">
        <div
          class="grid grid-cols-2 sm:grid-cols-3 xs-800:grid-cols-4 md:grid-cols-5 ml-4 justify-items-center gap-3"
          dir="rtl"
        >
          <div v-for="serie in getSeries" :key="serie.id">
            <CateSerie :item="serie" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center mt-36">
      <SpinnerView :loading="loading" />
    </div>
  </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import { useGenresStore } from '@/stores/genres'
import { usePortalStore } from '@/stores/portal'
import { useSlidersStore } from '@/stores/sliders'
import { mapActions, mapState } from 'pinia'
import CateSerie from '@/components/cards/CateSerie.vue'
import SpinnerView from '@/components/SpinnerView.vue'
import SwiperSlider from '@/components/carousel/SwiperSlider.vue'
export default {
  components: { HomeLayout, CateSerie, SpinnerView, SwiperSlider },

  data () {
    return {
      s3: 'http://systech-s24.s3.amazonaws.com/series/',
      loading: false,
      loaded: false
    }
  },
  computed: {
    ...mapState(useGenresStore, ['genresSeriesData', 'getSeries']),
    ...mapState(usePortalStore, ['portalData']),
    ...mapState(useSlidersStore, ['slidersData'])
  },
  methods: {
    ...mapActions(useGenresStore, ['showGenresSeries']),
    ...mapActions(usePortalStore, ['showPortalData']),
    ...mapActions(useSlidersStore, ['showSlidersData'])
  },
  async created () {
    this.loading = true
    try {
      await this.showGenresSeries(this.$route.params.id)
      await this.showSlidersData()
      this.loaded = true
      this.loading = false
    } catch (error) {
      console.error('Failed to grab portal data', error)
      this.loading = false
    }
  }
}
</script>

<style></style>
