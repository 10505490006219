<template>
  <div
    class="absloute flex flex-col  bottom-0  full mt-2 px-16 py-8 "
    dir="rtl"
  >
   <div class="flex justify-between space-x-2">
    <div class="flex flex-col gap-2">
      <div class="mt-2">
        <router-link to="/">
          <img
            src="@/assets/logo-1.webp"
            class=""
            alt="Nasan Logo"
            width="100"
            height="100"
          />
        </router-link>
      </div>
      <h3 class="text-gray-500 text-sm">للاستمتاع بأفضل تجربة مشاهدة حمل التطبيق الآن!</h3>
      <div class="flex  space-x-1">
             <a href="https://play.google.com/store/apps/details?id=com.systechprojects.nasna" class="mx-1"><PlayStore /></a>

        <a href="https://apps.apple.com/us/app/%D9%86%D8%A7%D8%B3%D9%86%D8%A7/id6737733144" class="mx-1">  <AppStore/>  </a>
       
      </div>
    </div>

    <div class=" flex flex-col items-center gap-2 ">
      <h3 class="text-gray-500">تابعنا على وسائل التواصل الإجتماعي</h3>
      <div class="flex  justify-center  ">
          <SvgView />
      </div>
    </div>
    <div
      class="flex flex-col justify-start gap-2 space-x-4 text-sm font-bold  sm:-ml-4"
    >
      <div class="ml-4">
        <a href="https://sites.google.com/view/nasnatv" class="text-gray-500"
          >الشروط والاحكام</a
        >
      </div>

      <div class="ml-4">
        <a href="https://sites.google.com/view/nasna-tv" class="text-gray-500"
          >سياسة الاستخدام</a
        >
      </div>
      <div class="ml-4">
        <a href="https://m.me/nasnaTv.SD" class="text-gray-500">اتصل بنا</a>
      </div>
    </div>
   </div>

   
    <div class=" flex justify-center mt-16 mr-44">
      <!-- <hr class="m-2 bg-gray-700 w-full " style=""> -->
      <small class="text-gray-700 text-center text-sm" style=""
        >جميع الحقوق محفوظة © ناسنا 2023</small
      >
    </div>
  </div>
</template>

<script>
import SvgView from "./SvgView.vue";
import PlayStore from "@/components/svg/PlayStore.vue";
import AppStore from "@/components/svg/AppStore.vue";

export default {
  components: { SvgView,AppStore,PlayStore },
};
</script>

<style></style>
