<template>
  <div
    class="container mx-auto flex flex-col items-center font-Almarai relative overflow-x-hidden"
  >
    <LogoSvg class="absolute" />

    <div class="flex justify-center items-center w-3/12  h-36">
      <img src="@/assets/logo-1.webp" alt="logo" class="w-28" />
    </div>
    <!-- Main Content -->
    <div
      class="flex flex-col justify-center items-center gap-y-6 bg-transparent rounded-md md:mx-auto mx-2"
    >
      <div class="my-2 flex flex-col justify-center items-center">
        <h1 class="text-gray-600 text-md my-2 font-Almarai">خطوة 2 من 2</h1>
        <h1 class="md:text-2xl font-bold text-white my-2 font-Almarai">معلومات الدفع</h1>
        <!-- daily section -->
        <div class="my-3 md:w-10/12 w-full md:px-6 px-1 py-4 rounded-md" dir="rtl">
          <div class="flex justify-between items-center mx-3">
            <h1 class="text-md text-white font-bold">اختر شبكة الموبايل</h1>
          </div>
          <!-- operators sections  -->
          <div class="flex justify-between p-2">
            <sudani-option
              @click="showOperator(0)"
              :selectedProvider="selectedProvider"
            />
            <zain-option @click="showOperator(1)" :selectedProvider="selectedProvider" />
            <mtn-option @click="showOperator(2)" :selectedProvider="selectedProvider" />
          </div>
        </div>
        <!-- end of daily -->

        <!-- end of stripe plans -->
      </div>
      <div class="flex flex-col items-center gap-y-2 justify-center w-full mx-auto my-3">
        <button
          @click.prevent="redirectUserToProvider"
          class="inline-flex justify-center items-center gap-2 md:w-10/12 w-full bg-[#9d2890] text-gray-100 rounded-md py-3"
        >
          <span> متابعة </span>
        </button>
        <button
          @click.prevent="$router.go(-1)"
          class="inline-flex justify-center items-center gap-2 md:w-10/12 w-full border border-basic text-gray-100 rounded-md py-3"
        >
          <span> رجوع </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import animationData from "@/assets/loader.json";
import LogoSvg from "@/components/svg/LogoSvg.vue";
import SudaniOption from "@/components/payments/SudaniOption.vue";
import MtnOption from "@/components/payments/MtnOption.vue";
import ZainOption from "@/components/payments/ZainOption.vue";

export default {
  components: {
    LogoSvg,
    SudaniOption,
    MtnOption,
    ZainOption,
  },
  data() {
    return {
      toast: useToast(),
      loaded: false,
      lottieOptions: {
        animationData,
        loop: true,
        autoplay: true,
      },
      jsonFile: animationData,
      providers: {
        sudani: "https://api.nasna.tv/api/auth/sd/subscriptions/redirect",
        zain: "https://dsplp.sd.zain.com/?p=7789312591",
        mtn: "https://cgmtnsd.digitalsp.net/632/OrMW",
      },
      selectedProvider: null,
    };
  },

  methods: {
    showOperator(id) {
      switch (id) {
        case 0:
          this.selectedProvider = "sudani";
          break;
        case 1:
          this.selectedProvider = "zain";
          break;
        case 2:
          this.selectedProvider = "mtn";
          break;
        default:
          this.selectedProvider = "sudani";
      }
    },

    redirectUserToProvider() {
      this.loaded = true;
      if (this.selectedProvider) {
        if (this.providers[this.selectedProvider]) {
          window.location.href = this.providers[this.selectedProvider];
        } else {
          this.toast.error("احتر الباقة المناسبة ");
        }
      }
      this.loaded = false;
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped></style>
