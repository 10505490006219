import { AUTHTOKEN } from "@/constants";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from '@apollo/client/link/context';
import { encryptData } from '@/plugins/encrypt';
// HTTP connection to the API
const httpLink = createHttpLink({
  uri: "https://graph.nasna.tv/"
});


// Example usage in an Apollo mutation
const data = process.env.VUE_APP_OTP_SECRET_VALUE;
const encryptedToken = encryptData(data);

// Middleware to add the custom header
const authLink = setContext((_, { headers }) => {
  // Get the access token from local storage or any other storage
  const token = localStorage.getItem(AUTHTOKEN);
  return {
    headers: {
      ...headers,
      'access-token': token ? token : "",
      'x-otp-token' : encryptedToken ? encryptedToken : ""
    }
  };
});
// Cache implementation
const cache = new InMemoryCache();

// Create the Apollo Client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default apolloClient;