<template>
  <div
    class="flex justify-center rounded-lg mt-12 container mx-auto shadow-md bg-basic-dark drop-shadow-2xl  lg:p-6 relative"
  >
    <div
      v-show="showConfirmatation"
      class="absolute top-1/2  left-1/2 inset-x-0 -translate-x-1/2 md:w-[500px]  w-10/12 p-6 bg-black border border-gray-600 rounded-md "
    >
      <div class="text-white mb-2 text-right">
        هل أنت متأكد من أنك تريد إلغاء إشتراكك؟
      </div>
      <div class="flex justify-between space-x-3 mt-6">
        <button
          @click="showConfirmatation = false"
          class="py-1.5 px-3 bg-gray-600 text-white  w-full text-sm rounded-md"
        >
          رجوع
        </button>
        <button
        @click="cancelStripeSubscription"
          class="py-1.5 px-3 bg-red-600  text-white w-full text-sm rounded-md"
        >
           تأكيد الإلغاء
        </button>
      </div>
    </div>
    <div class="md:max-w-2xl  w-full p-1" dir="rtl">
      <div class="md:px-4 p-1  rounded-md place-items-center">
        <h1 class="text-gray-500">معلومات الاشتراك</h1>
        <div class="mt-2 flex flex-col gap-2  w-full" v-if="getUserDtata">
          <div
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class="">نوع الإشتراك</span
            ><span class="">{{ getUserDtata.plan?.name }}</span>
          </div>

          <div
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class=""> بداية الإشتراك</span
            ><span class="">{{
              formatDate(getUserDtata.subscription_starts_at)
            }}</span>
          </div>
          <div
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class=""> نهاية الإشتراك</span
            ><span class="">{{
              formatDate(getUserDtata.subscription_ends_at)
            }}</span>
          </div>
          <div
            v-show="getUserDtata.cancelled_at"
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class=""> تم إلغاء الإشتراك بتاريخ</span
            ><span class="">{{ formatDate(getUserDtata.cancelled_at) }}</span>
          </div>
        </div>
        <div class="w-full" v-else>
          <div
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class="">ليس لديك إشتراك</span>
            <span
              @click="$router.push({ name: 'auth.subscriptions' })"
              class="cursor-pointer underline border py-1 px-3 rounded-md"
              >اشترك الان</span
            >
          </div>
        </div>
      </div>
      <div class="px-4 p-1 rounded-md place-items-center my-3 w-full">
        <h1 class="text-gray-500">الأجهزة المتصلة</h1>
        <div class="mt-2 flex flex-col gap-2 w-full">
          <div
            v-for="(device, index) in getDevices"
            :key="index"
            class="flex justify-between py-2 md:px-6 px-2 w-full text-gray-500 rounded-md bg-[#1E2023]"
          >
            <span class="">
              {{ device.device_type }} :{{ getShortName(device.name) }}</span
            >
            <button
              @click="logoutFromDeviceAction(device.id)"
              class="py-1.5 px-3 bg-gray-600 text-white text-sm rounded-md"
              :class="device.isCurrentDevice ? 'hidden' : ''"
            >
              خروج
            </button>
          </div>
        </div>
      </div>
      <div class="px-4 p-1 rounded-md place-items-center my-3 w-full">
        <h1 class="text-gray-500"></h1>
        <div class="mt-2 flex flex-col gap-2 w-full">
          <div
            class="flex justify-center gap-2 py-2 md:px-6 px-2 w-full text-gray-500 rounded-md  bg-[#1E2023]"
          >
            <button
              @click="logoutCurrentDevice()"
              class="py-1.5 px-3 bg-gray-600 text-white w-full text-sm rounded-md "
            >
              تسجيل خروج
            </button>
            <button
            v-show="getUserDtata"
              @click="showConfirmatation = true"
              class="py-1.5 px-3 text-sm rounded-md bg-red-900 text-white w-full"
            >
            
              <!-- TODO change this button if the user canceled sub -->
              إلغاء الإشتراك
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions, mapState } from "pinia";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      isDone: false,
      isActive: false,
      toast: useToast(),
      confirmed: false,
      showConfirmatation: false,
    };
  },

  components: {},
  computed: {
    ...mapState(useAuthStore, [
      "getUserDtata",
      "getDevices",
      "logoutFromDeviceAction",
      "logout",
      "cancelStripeSubscriptionAction",
    ]),
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line
    getUserDtata(newValue, oldValue) {},
    deep: true,
    immediate: true,
  },
  methods: {
    ...mapActions(useAuthStore, [
      "getUserProfileAction",
      "getConnectedDevicesAction",
    ]),
    checkIsActive() {
      return (this.isActive = localStorage.getItem("isActive"));
    },
    confirmUnsub() {
      return (this.confirmed = true);
    },
    async cancelStripeSubscription() {
      await this.cancelStripeSubscriptionAction().then((response) => {
        if (response.data.cancelStripeSubscription.success) {
          this.toast.success("تم الغاء الإشتراك");
        } else {
          this.toast.error(" ليس لديك إشتراك");
        }
        setTimeout(
          () => {
            window.location.reload();
          },

          1000
        );
      });
    },
    async logoutCurrentDevice() {
      await this.logout().then((response) => {
        if (response?.data?.revokeAccessToken?.success) {
          this.toast.info("تم تسجيل الخروج");
          this.$router.push("/");
        } else {
          this.toast.error("حدث خطأ ما");
        }
      });
    },
    formatDate(timestamp) {
      if (timestamp == null) return;
      const date = new Date(parseInt(timestamp));
      return new Intl.DateTimeFormat("ar-EG", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(date);
    },
    getShortName(userAgent) {
      const browserRegex =
        /(Firefox|Chrome|Safari|Opera|Edg|MSIE|Trident)\/?\s*(\d+)/;
      const match = userAgent.match(browserRegex);
      if (match) {
        return `${match[1]} ${match[2]}`;
      }
      return userAgent;
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getUserProfileAction(),
        this.getConnectedDevicesAction(),
      ]);
      
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-basic {
  background-color: #181b30;
}
</style>
