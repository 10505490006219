<template>
  <transition>
    <div
      class="carousel-item w-full h-full absolute top-0 left-0 object-cover z-0 bg-center bg-cover slider cursor-pointer"
      :style="{ backgroundImage: `url(${image(slide)})` }"
      @click="
        $router.push({ name: 'watch.now', params: { id: slide.serie_id } })
      "
    >
      <div
        class="absolute inset-y-0 right-0 w-1/2 h-full right-shadow text-white"
      ></div>
      <div
        class="absolute bottom-0 inset-x-0 h-64 bottom-shadow text-white"
      ></div>

      <!-- main description -->
      <div
        class="absolute bottom-[1rem] right-1/2 md:right-0 md:-translate-x-0 translate-x-1/2 my-4 md:mr-10 md:w-[600px] flex flex-col justify-center items-center md:items-start"
        dir="rtl"
      >
        <div
          class="flex md:justify-start justify-center items-center w-36 h-36"
        >
          <img
            :src="getLogo(slide)"
            alt="logo"
            class="object-fit"
            style="object-fit: contain !important"
          />
        </div>
        <div class="hidden md:flex justify-start space-x-1 my-1">
          <div
            class="md:py-1 md:px-3 py-1 px-1 mx-1 tag-bg border border-gray-300 rounded-[25px] text-white text-[1vw] font-medium"
            v-for="tag in slide.serie.tags"
            :key="tag.id"
          >
            {{ tag.name }}
          </div>
        </div>
        <div
          class="md:hidden text-white space-x-1 flex flex-row justify-center items-center w-full"
        >
          <div
            class="flex justify-center space-x-1 items-center"
            v-for="(tag, index) in slide.serie.tags"
            :key="tag.id"
            dir="rtl"
          >
            <span class="text-sm"> {{ tag.name }}</span>
            <span
              v-if="
                slide.serie.tags?.length > 1 &&
                index < slide.serie.tags.length - 1
              "
              class="text-4xl font-bold mb-1"
            >
              {{ "." }}
            </span>
          </div>
        </div>
        <p
          class="hidden md:block text-white my-3 text-[1vw] font-medium leading-6 z-20 max-w-lg text-right"
        >
          {{ slide.desc ? slide.desc : "" }}
        </p>
        <div class="flex md:justify-start justify-center items-center my-3">
          <router-link :to="`/watch/now/${slide.serie_id}`">
            <button
              v-show="slide.desc != 'null'"
              type="button"
              class="bg-white rounded-[30px] md:px-6 md:py-2 px-3 py-2 text-black font-semibold text-center inline-flex items-center text-xl mt-2 z-20 btn-top text-md lg:text-lg"

              tabindex="0"
            >
              {{ "شاهد الان" }}
              <play-svg />
            </button>
          </router-link>
        </div>
      </div>

      <!-- end of main description -->
    </div>
  </transition>
</template>

<script>
import { S3_BASE_URL } from "@/constants";
import PlaySvg from "../svg/PlaySvg.vue";
export default {
  components: { PlaySvg },

  props: ["slide"],
  data() {
    return {
      base_url: S3_BASE_URL,
      type: "sliders",
    };
  },
  computed: {},
  methods: {
    getLogo(slide) {
      if (slide?.serie?.logo) {
        return `${this.base_url}series/${slide.serie.logo}`;
      }
    },
    image(slider) {
      if (slider.image) {
        return `${this.base_url}${this.type}/${slider.image}`;
      } else {
        return require("@/assets/" + "placeholder-wide.webp");
      }
    },
  },
};
</script>

<style scoped>
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-bg {
  background-color: #9d2890;
}

.text-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
}

.focusable:focus {
  outline: 3px white;
  border-radius: 8px;
  background-color: white;
}
</style>
