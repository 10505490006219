<template>
    <div
              class="form-group w-1/2 mx-2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-4 cursor-pointer"
              :class="
                selectedProvider == 'zain' ? ' border-2 border-[#9d2890]' : ''
              "
             
            >
              <span
                v-if="selectedProvider === 'zain'"
                class="h-5 w-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3 text-white">{{ "✓" }}</span></span
              >
              <div class="lg:px-8 md:px-6">
                <img
                  src="@/assets/tele/zain.png"
                  class="my-2 px-2"
                  alt="logo"
                />
              </div>
            </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {};
  },
  props: ["selectedProvider"],
  methods: {},
};
</script>

<style lang="scss" scoped></style>