<template>
  <div class="bg-red-900 w-full">
    <HomeLayout :data="true">

    <swiper-slider />
    </HomeLayout>
  </div>
</template>

<script>
import SwiperSlider from "@/components/carousel/SwiperSlider.vue";
import HomeLayout from "@/components/HomeLayout.vue";

export default {
  components: { SwiperSlider,HomeLayout, },
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

