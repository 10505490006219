<template>
  <a
    href="https://www.instagram.com/nasna_tv/"
    target="_blank"
    class="text-gray-400 rounded-full p-2 mx-2"
    style="background-color: #1b1c23"
    aria-label="insta "
  >
    <svg
      width="38"
      height="39"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24.9131C0 11.6583 10.7452 0.913086 24 0.913086C37.2548 0.913086 48 11.6583 48 24.9131C48 38.1679 37.2548 48.9131 24 48.9131C10.7452 48.9131 0 38.1679 0 24.9131Z"
        fill="#1B1C23"
      />
      <path
        d="M24.0027 18.7614C20.5974 18.7614 17.8508 21.5081 17.8508 24.9133C17.8508 28.3186 20.5974 31.0653 24.0027 31.0653C27.4079 31.0653 30.1546 28.3186 30.1546 24.9133C30.1546 21.5081 27.4079 18.7614 24.0027 18.7614ZM24.0027 28.9129C21.8021 28.9129 20.0031 27.1193 20.0031 24.9133C20.0031 22.7074 21.7968 20.9138 24.0027 20.9138C26.2086 20.9138 28.0022 22.7074 28.0022 24.9133C28.0022 27.1193 26.2032 28.9129 24.0027 28.9129ZM31.8412 18.5098C31.8412 19.3075 31.1987 19.9447 30.4062 19.9447C29.6085 19.9447 28.9713 19.3022 28.9713 18.5098C28.9713 17.7174 29.6138 17.0749 30.4062 17.0749C31.1987 17.0749 31.8412 17.7174 31.8412 18.5098ZM35.9157 19.9661C35.8247 18.044 35.3856 16.3413 33.9775 14.9385C32.5747 13.5358 30.8721 13.0967 28.9499 13.0003C26.9689 12.8879 21.0311 12.8879 19.0501 13.0003C17.1333 13.0914 15.4307 13.5304 14.0225 14.9332C12.6144 16.336 12.1807 18.0386 12.0843 19.9607C11.9719 21.9418 11.9719 27.8795 12.0843 29.8606C12.1753 31.7827 12.6144 33.4853 14.0225 34.8881C15.4307 36.2909 17.1279 36.73 19.0501 36.8263C21.0311 36.9388 26.9689 36.9388 28.9499 36.8263C30.8721 36.7353 32.5747 36.2963 33.9775 34.8881C35.3803 33.4853 35.8193 31.7827 35.9157 29.8606C36.0281 27.8795 36.0281 21.9471 35.9157 19.9661ZM33.3564 31.9862C32.9388 33.0356 32.1303 33.8441 31.0755 34.267C29.496 34.8935 25.7481 34.7489 24.0027 34.7489C22.2572 34.7489 18.504 34.8881 16.9298 34.267C15.8804 33.8494 15.0719 33.0409 14.649 31.9862C14.0225 30.4067 14.1671 26.6588 14.1671 24.9133C14.1671 23.1679 14.0279 19.4146 14.649 17.8405C15.0666 16.7911 15.8751 15.9826 16.9298 15.5596C18.5093 14.9332 22.2572 15.0778 24.0027 15.0778C25.7481 15.0778 29.5014 14.9385 31.0755 15.5596C32.1249 15.9773 32.9334 16.7857 33.3564 17.8405C33.9828 19.42 33.8383 23.1679 33.8383 24.9133C33.8383 26.6588 33.9828 30.4121 33.3564 31.9862Z"
        fill="#999999"
      />
    </svg>
  </a>
  <a
    href="https://www.facebook.com/NasnaTv.SD"
    target="_blank"
    class="text-gray-400 rounded-full p-2 mx-2"
    style="background-color: #1b1c23"
    aria-label="insta "
  >
    <svg
      width="38"
      height="39"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24.9131C0 11.6583 10.7452 0.913086 24 0.913086C37.2548 0.913086 48 11.6583 48 24.9131C48 38.1679 37.2548 48.9131 24 48.9131C10.7452 48.9131 0 38.1679 0 24.9131Z"
        fill="#1B1C23"
      />
      <path
        d="M36 24.9854C36 18.3563 30.629 12.9854 24 12.9854C17.371 12.9854 12 18.3563 12 24.9854C12 30.9747 16.3882 35.9392 22.125 36.8402V28.4542H19.0766V24.9854H22.125V22.3415C22.125 19.3342 23.9153 17.6731 26.6574 17.6731C27.9706 17.6731 29.3439 17.9073 29.3439 17.9073V20.8589H27.8303C26.34 20.8589 25.875 21.7841 25.875 22.7329V24.9854H29.2031L28.6708 28.4542H25.875V36.8402C31.6118 35.9392 36 30.9747 36 24.9854Z"
        fill="#999999"
      />
    </svg>
  </a>

  <a
    href="https://www.youtube.com/@nasnatvsd"
    target="blank"
    class="text-gray-400 rounded-full p-1.5 mx-2"
    style="background-color: #1b1c23"
    aria-label="twitter"
  >
    <svg
      width="38"
      height="39"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24.9131C0 11.6583 10.7452 0.913086 24 0.913086C37.2548 0.913086 48 11.6583 48 24.9131C48 38.1679 37.2548 48.9131 24 48.9131C10.7452 48.9131 0 38.1679 0 24.9131Z"
        fill="#1B1C23"
      />
      <path
        d="M35.4985 19.1159C35.2225 18.0766 34.4092 17.2581 33.3766 16.9803C31.505 16.4756 24 16.4756 24 16.4756C24 16.4756 16.495 16.4756 14.6234 16.9803C13.5908 17.2582 12.7775 18.0766 12.5015 19.1159C12 20.9998 12 24.9301 12 24.9301C12 24.9301 12 28.8605 12.5015 30.7443C12.7775 31.7836 13.5908 32.568 14.6234 32.8458C16.495 33.3506 24 33.3506 24 33.3506C24 33.3506 31.505 33.3506 33.3766 32.8458C34.4092 32.568 35.2225 31.7836 35.4985 30.7443C36 28.8605 36 24.9301 36 24.9301C36 24.9301 36 20.9998 35.4985 19.1159ZM21.5454 28.4986V21.3616L27.8181 24.9302L21.5454 28.4986Z"
        fill="#999999"
      />
    </svg>
  </a>
  <a
    href="https://www.tiktok.com/@nasna_tv"
    target="blank"
    class="text-gray-400 rounded-full p-1.5 mx-2"
    style="background-color: #1b1c23"
    aria-label="twitter"
  >
    <svg
      width="38"
      height="39"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24.9131C0 11.6583 10.7452 0.913086 24 0.913086C37.2548 0.913086 48 11.6583 48 24.9131C48 38.1679 37.2548 48.9131 24 48.9131C10.7452 48.9131 0 38.1679 0 24.9131Z"
        fill="#1B1C23"
      />
      <path
        d="M33 23.0633C31.2306 23.0675 29.5046 22.5157 28.0658 21.4858V28.6686C28.0654 29.9989 27.6587 31.2974 26.9004 32.3904C26.142 33.4834 25.068 34.3188 23.822 34.785C22.576 35.2511 21.2174 35.3257 19.9278 34.9989C18.6383 34.672 17.4792 33.9593 16.6057 32.9559C15.7322 31.9526 15.1858 30.7064 15.0396 29.3842C14.8934 28.0619 15.1543 26.7265 15.7876 25.5565C16.4208 24.3866 17.3961 23.4378 18.5831 22.8372C19.7701 22.2365 21.1122 22.0125 22.43 22.1952V25.8079C21.827 25.6182 21.1795 25.6239 20.5799 25.8242C19.9804 26.0245 19.4594 26.4091 19.0915 26.9231C18.7236 27.4371 18.5274 28.0542 18.5312 28.6863C18.5349 29.3185 18.7383 29.9332 19.1122 30.4429C19.4862 30.9525 20.0116 31.331 20.6135 31.5242C21.2154 31.7174 21.8629 31.7154 22.4636 31.5187C23.0643 31.3219 23.5875 30.9404 23.9585 30.4285C24.3294 29.9167 24.5291 29.3007 24.5291 28.6686V14.627H28.0658C28.0634 14.9256 28.0884 15.2239 28.1406 15.518C28.2635 16.1745 28.519 16.799 28.8916 17.3534C29.2641 17.9077 29.7458 18.3802 30.3073 18.742C31.106 19.2702 32.0424 19.5517 33 19.5515V23.0633Z"
        fill="#999999"
      />
    </svg>
  </a>
</template>

<script>
export default {};
</script>

<style>
</style>