import LoginView from '@/views/auth/LoginView.vue'
import SubscribeDiffrent from '@/views/auth/SubscribeDiffrent.vue'
import SubscribeView from '@/views/auth/SubscribeView.vue'
import SuccessOk from '@/views/auth/SuccessOk'
import ProfileStatus from '@/views/auth/ProfileStatus'
import UnsubscribedSuccess from '@/views/auth/UnsubscribedSuccess'
import ConfirmLogout from '@/views/auth/ConfirmLogout'
import ConfirmUnsubscribe from '@/views/auth/ConfirmUnsubscribe'

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: SubscribeDiffrent,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/subscribe/headerinerchment',
    name: 'subscribe.header',
    component: SubscribeView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/login/subscribe_ok/:phoneNumber',
    name: 'subscribe_ok',
    component: SuccessOk,
    meta: {
      auth: false
    }
  },
  {
    path: '/unsubscribed',
    name: 'unsubscribe',
    component: UnsubscribedSuccess,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/confirm/logout',
    name: 'confirm.logout',
    component: ConfirmLogout,
    meta: {
      footer: false,
      auth: true
    }
  },
  {
    path: '/confirm/unsubscribe',
    name: 'confirm.unsubscribe',
    component: ConfirmUnsubscribe,
    meta: {
      footer: false,
      auth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileStatus,
    meta: {
      footer: true,
      auth: true
    }
  }
]