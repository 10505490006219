<template>
  <div class="h-screen flex flex-col items-center relative overflow-hidden">
    <LogoSvg />
    <div
      class="flex justify-center items-center w-3/12 absolute top-3 left-1/2 -translate-x-1/2"
    >
      <img src="@/assets/logo-1.webp" alt="logo" />
    </div>
    <!-- Main Content -->
    <div
      class="px-4 p-1 flex flex-col justify-center bg-transparent shadow-md drop-shadow-2xl lg:p-2 rounded-md mb-36"
    >
      <div class="text-center">
        <div class="mt-0">
          <!-- Lottie Voice Animation -->
          <Vue3Lottie
            :animationData="jsonFile"
            :height="300"
            :width="300"
            class="rounded-full"
          />
        </div>
        <p class="text-white text-lg font-bold" v-show="successStatus">تم  الإشتراك بنجاح!</p>
        <p class="text-gray-500 text-lg font-bold" v-show="successStatus">
          يمكنك الآن متابعة المشاهدة
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import animationData from "@/assets/success.json";
import LogoSvg from "@/components/svg/LogoSvg.vue";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
export default {
  components: {
    Vue3Lottie,
    LogoSvg,
  },
  data() {
    return {
      lottieOptions: {
        animationData,
        loop: true,
        autoplay: true,
      },
      jsonFile: animationData,
      successStatus: false,
    };
  },
  methods: {
   ...mapActions(useAuthStore, ["checkStatus"]),
  },
 async created() {
    const hashOrQuery = this.$route.fullPath.includes("#")
    ? this.$route.fullPath.split("#")[1]
    : this.$route.fullPath.includes("?")
    ? this.$route.fullPath.split("?")[1]
    : "";
    const params = new URLSearchParams(hashOrQuery);
    const queryObject = Object.fromEntries(params.entries());
    console.log("Query Object", queryObject);
    const response = await axios.get(
      `https://api.nasna.tv/api/stripe/check-payment-intent?${hashOrQuery}`
    );
    if (response.data.success) {
      this.successStatus = true;
      this.checkStatus();
      localStorage.removeItem("selectedPrice")
      setTimeout(() => {
        this.$router.push({ name: "home" });
      }, 3000);
    }
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped></style>
