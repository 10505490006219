<template>
  <div class="carsouel h-full absolute top-0  md:w-full w-full mx-3 px-2 mt-1">
    <div class="carsoule-inner relative h-64 overflow-hidden md:h-96">
      <carousel-indicators
        v-if="indicators"
        :total="slidersData.length"
        :current-index="currentSlide"
        @switch="switchSlide($event)"
      ></carousel-indicators>
      <div v-show="!loaded">
        <auth-items
          v-for="(slide, index) in slidersData"
          :slide="slide"
          :key="`item-${index}`"
          :current-slide="currentSlide"
          :index="index"
          :direction="direction"
          @mouseenter="stopSlideTimer"
          @mouseout="startSlideTimer"
          @swipeDirection="swipeDirection"
        ></auth-items>
      </div>
      <div v-show="loaded">
        <carosuel-loader />
      </div>
    </div>
  </div>
</template>
  
  <script>
import AuthItems from "./AuthItems.vue";
import { mapState, mapActions } from "pinia";
import CarouselIndicators from "./CarouselIndicators.vue";
import { useSlidersStore } from "@/stores/sliders";
import CarosuelLoader from "./CarosuelLoader.vue";
export default {
  props: {
    controls: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },
  components: { AuthItems, CarouselIndicators, CarosuelLoader },
  data: () => ({
    currentSlide: 0,
    slideInterval: null,
    direction: "right",
    loaded:false,
  }),

  computed: {
    ...mapState(useSlidersStore, ["slidersData"]),
  },
  methods: {
    ...mapActions(useSlidersStore, ["showSlidersData"]),
    setCurrentSlide(index) {
      this.currentSlide = index;
    },
    prev(step = -1) {
      const index =
        this.currentSlide > 0
          ? this.currentSlide + step
          : this.slidersData.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
      this.startSlideTimer();
    },
    _next(step = 1) {
      const index =
        this.currentSlide < this.slidersData.length - 1
          ? this.currentSlide + step
          : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
    },
    next(step = 1) {
      this._next(step);
      this.startSlideTimer();
    },
    startSlideTimer() {
      this.stopSlideTimer();
      this.slideInterval = setInterval(() => {
        this._next();
      }, 6000);
    },
    stopSlideTimer() {
      clearInterval(this.slideInterval);
    },
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
    swipeDirection(event) {
      // alert(event.changedTouches[0].screenX )
      if (event.changedTouches[0].screenX > 180) {
        this.next();
      } else {
        this.prev();
      }
    },
  },
  mounted() {
    this.startSlideTimer();
  },
  beforeUnmount() {
    this.stopSlideTimer();
  },
  async created() {

    this.loaded=true;
    try{
      await this.showSlidersData();
      this.loaded=false
    }catch(error){
      this.loaded=false;
      console.log(this.error)
    }
  },
};
</script>
  
  <style scoped>
.carousel {
  display: flex;
  justify-content: center;
  border: none;
}
.carousel-inner {
  position: relative;
  width: 100%;

  overflow: hidden;
  border: none;
}
</style>
  