import { defineStore } from "pinia";
import { acceptTVCode, authVia, cancelStripeSubscription, getConnectedDevices, getUserProfile, GetUserSubscriptionStatus, logout, logoutFromDevice, verifyOpt } from "@/Gql/auth.js";
import apolloClient from "../plugins/apollo";
import { AUTHTOKEN, ISACTIVE, isSubscribed, USER } from "@/constants.js";


export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      isLoggedin: Boolean, // check user status 
      user: JSON.parse(localStorage.getItem("user")), // user data to be stored 
      token: "",
      phone: "",
      userData: null,
      devices: null

    }
  },// end of state
  getters: {
    getUser: (state) => state.user,
    isAuthenticated: (state) => state.user != undefined,
    getPhone: (state) => state.phone,
    getUserDtata: (state) => state.userData,
    getDevices: (state) => state.devices

  }, //end of getters 
  actions: {

    async authViaAction(id, via) {

      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: authVia,
            variables: {
              input: {
                id: id,
                via: via
              }
            },
            
          })
          .then((response) => {


            this.setData(authVia)
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },

    async verifyOptAction(id, otp, via) {

      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: verifyOpt,
            variables: {
              input: {
                id: id,
                otp: otp,
                via: via
              }
            },
          })
          .then((response) => {
            const { authViaVerify } = response.data;

            this.setData(authViaVerify)
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },
    async logoutFromDeviceAction(id) {
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: logoutFromDevice,
            variables: {
              logoutFromDeviceId: id
            },

          })
          .then((response) => {
            if (response.data.logoutFromDevice.success) {
              this.devices = this.devices.filter((device) => device.id !== id)
            }
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },
    async cancelStripeSubscriptionAction(){
      const user = localStorage.getItem(USER);
      if (!user) return;
    
      const userId = JSON.parse(user)?.id;
  
      
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: cancelStripeSubscription,
            variables: {
              input:{
                user_id: userId
              }
            },

          })
          .then((response) => {
              
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },
    async logout() {

      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: logout,


          })
          .then((response) => {
            // const { revokeAccessToken } = response.data;
            // if (revokeAccessToken?.success) {
            this.wipeUserData();
            // }
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },


    /**
     * login user for authentication purpose 
     */
    async verifyTvPinCode(userID, code) {
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: acceptTVCode,
            variables: {
              userId: userID, // Corrected variable name
              code
            },
          })
          .then((response) => {
            console.log(response.data.acceptTVCode);
            resolve(response);
          })
          .catch((error) => {
            this.error = error;
            reject(error);
          });
      });
    },
    async checkStatus() {
      await apolloClient
        .query({
          query: GetUserSubscriptionStatus,

        }).then(async res => {
          if (res.data.getUserSubscriptionStatus.is_active) {
            this.isLoggedin = true;
            localStorage.setItem(isSubscribed, true);
          } else {
            this.isLoggedin = false;
            localStorage.setItem(isSubscribed, false);
          }
        })
        .catch(error => {
          this.error = error
        })
    },
    async getUserProfileAction() {
      await apolloClient
        .query({
          query: getUserProfile,

        }).then(async res => {
          if (res.data.userProfile.success) {
            this.userData = res.data.userProfile.subscription;
          }
        })
        .catch(error => {
          this.error = error
        })
    },
    async getConnectedDevicesAction() {
      await apolloClient
        .query({
          query: getConnectedDevices,

        }).then(async res => {
          if (res.data.userConnectedDevices) {
            this.devices = res.data.userConnectedDevices.data;
          }
        })
        .catch(error => {
          this.error = error
        })
    },

    async setData(authViaVerify) {
      if (authViaVerify.success) {

        window.localStorage.setItem(USER, JSON.stringify(authViaVerify.user));
        window.localStorage.setItem(AUTHTOKEN, authViaVerify.token);
        window.localStorage.setItem(ISACTIVE, authViaVerify.token ? true : false);

      } else {
        this.wipeUserData()
      }

    },


    wipeUserData() {
      window.localStorage.removeItem(USER);
      window.localStorage.removeItem(AUTHTOKEN);
      window.localStorage.removeItem(ISACTIVE);

    },



  }
})