<template>
  <div class="flex justify-center rounded-lg mx-2 mt-12">
    <div class="">
      <div
        class="container mx-auto px-4 p-1 bg-basic shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center"
      >
        <div class="flex justify-center">
          <div v-if="isLoading">
            <div class="z-50 flex justify-center items-center">
              <div
                class="animate-spin rounded-full md:h-32 md:w-32 h-24 w-24 border-t-2 border-b-2 border-gray-100"
              ></div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <p class="text-md text-white text-center">الرجاء الانتظار</p>
          <div class="border border-b-2 border-color mx-auto mt-1 w-36"></div>

          <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { AUTHTOKEN, ISACTIVE, USER } from "@/constants";
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
let isLoading = ref(true);
let data = reactive({});

const route = useRoute();
const router = useRouter();
const authProvider = route.params.provider;

onMounted(async () => {

  /**
   * Construct URL for the callback route.
   */
  const api_url = `https://api.nasna.tv/api/auth/${authProvider}/callback`;

  const url = new URL(api_url);

  /**
   * Add the query provided by google.
   */
  url.search = window.location.search;

  /**
   * Send the final request. You can use Axios if you want.
   */

  if (authProvider == "apple") {
    const hashOrQuery = route.fullPath.includes("#")
    ? route.fullPath.split("#")[1]
    : route.fullPath.includes("?")
    ? route.fullPath.split("?")[1]
    : "";
  // Parse the extracted query string into an object
  const params = new URLSearchParams(hashOrQuery);
  const queryObject = Object.fromEntries(params.entries());
    const response = await axios
      .post(
        url.toString(),
        queryObject ,
        {
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        }
      )
      .then((response) => response);

    data = response.data;

    
  } else {
    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    }).then((response) => response.json());
    // print response
    data = response;

   
  }
  if (data?.success) {
      window.localStorage.setItem(USER, JSON.stringify(data.user));
      window.localStorage.setItem(AUTHTOKEN, data.token);
      window.localStorage.setItem(ISACTIVE, data.token ? true : false);
      isLoading.value = false;
      
        router.push("/");
     
    } else {
      console.log("SOMETHING WENT WRONG");
    }
});
</script>

<style></style>
