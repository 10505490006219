<template>
  <div class="pt-1 relative mx-auto text-gray-600">
    <input
      class="border-1 border-color mb-1 bg-black h-14 px-0 pr-16 lg:pr-5 lg:w-80 md:pr-5 md:w-52 rounded-lg text-sm text-white border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
      type="search"
      dir="rtl"
      placeholder="ابحث عن برنامج،مسلسل، أو فيلم"
      v-model="text"
      @keydown.enter="searchData"
    />
    <button
      aria-label="search submit"
      type="submit"
      class="absolute left-0 -top-3 w-12 px-3  mt-5 ml-1 py-1 h-12 btn-color rounded-md"
      @click="searchData"
    >
     <search-svg v-show="!loaded" />
      <div role="status" v-show="loaded">
        <search-loading/>
        <span class="sr-only">Loading...</span>
      </div>
    </button>
  </div>
</template>

<script>
import { useSearchStore } from '@/stores/Search'
import { mapActions } from 'pinia'
import SearchSvg from '../svg/SearchSvg.vue'
import SearchLoading from '../svg/SearchLoading.vue'
export default {
  components: { SearchSvg, SearchLoading },
  data () {
    return {
      text: '',
      loaded: false,
      page: 1,
      limit: 20
    }
  },
  methods: {
    ...mapActions(useSearchStore, ['ShowSearchData']),
    async searchData () {
      if (this.text != '') {
        this.loaded = true
        try {
          await this.ShowSearchData(
            this.page,
            this.limit,

            this.text
          ).then(
            ()=> {
            this.$router.push({ name: 'results',params:{
              query:this.text
            } });
            this.loaded = false
            }
          )
        } catch (error) {
          console.error('Failed to series  data', error)
        }
      }
    }
  }
}
</script>

<style scoped>
input[type='search']::placeholder {
  text-align: right;
}
.btn-color {
  background-color: #9d2890;
}
.grow-search {
  flex-grow: 1;
}
</style>
