<template>
  <div class="md:mx-auto">
    <HomeLayout :data="false"></HomeLayout>
    <!-- Conditionally render the loader or iframe -->
    <div class="md:container mx-auto">
      <!-- This padding-top percentage gives us a 16:9 aspect ratio -->
      <div class="video-container">
        <!-- Show loader when getLive is null or loading -->
        <div v-if="!getLive" class="loader">Loading...</div>
        <!-- Show iframe when getLive is available -->
        <iframe
          :src="getLive?.streaming_url"
          title="NasnaTV"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLayout from "@/components/HomeLayout.vue";
import { useLivesStore } from "@/stores/live";
import { mapState, mapActions } from "pinia";

export default {
  components: { HomeLayout },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(useLivesStore, ["getLive"]),
  },
  async created() {
    console.log("start fetching live ")
    try{
      await this.showLive(this.$route.params.id);
    }catch(error){
      console.log(error)
    }
    console.log(this.getLive)
  },
  methods: {
    ...mapActions(useLivesStore, ["showLive"]),
  },
};
</script>

<style scoped>
/* Customize the embedded stream container */
.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove iframe border */
  border-radius: 10px; /* Rounded corners for a modern look */
  overflow: hidden; /* Hide horizontal overflow */
}

/* Style the iframe title (optional) */
iframe[title="africaTV"] {
  font-size: 18px; /* Adjust the title font size */
  font-weight: bold; /* Make the title bold */
  padding: 2px; /* Add padding around the title */
}

/* Style the iframe content (optional) */
iframe[title="africaTV"] body {
  margin: 0; /* Reset margin inside the iframe */
  padding: 1px; /* Add padding inside the iframe content */
  width: 100%;
  height: 100%;
}
</style>
