<template>
  <footer v-if="$route.meta.footer"
    class="h-full md:h-full relative px-1  md:content-start content-center  border-t border-gray-600"
   style="background-color: #0B0C0E; user-select: none; "
  >
  <div class="md:hidden">
    <floating-view/>

  </div>
   <div class="hidden md:block">
    <desktop-footer/>
   </div>
  
  </footer>
</template>

<script>
import DesktopFooter from './DesktopFooter.vue';
import FloatingView from './FloatingView.vue';
export default { components: { FloatingView, DesktopFooter } ,

};
</script>

<style scoped>
.m-right {
  margin-right: 30%;
}

footer {
  clear: both;
  position: relative;
}
.text-xss {
  font-size: 9px;
}
</style>
