import SuccessView from '@/views/response/SuccessView.vue'
import FailureView from '@/views/response/FailureView.vue'

export const responseRoutes = [
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/failed',
    name: 'failed',
    component: FailureView,
    meta: {
      footer: false,
      auth: false
    }
  }
]