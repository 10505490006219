import gql from "graphql-tag";


export const acceptTVCode = gql`mutation Mutation($userId: String!, $code: String!) {
  acceptTVCode(userID: $userId, code: $code) {
    success
    error
    message
    code
  }
}`

export const getUserProfile = gql`
query UserProfile {
  userProfile {
    success
    error
    message
    subscription {
      id
      currency
      cancelled_at
      subscription_starts_at
      subscription_ends_at
      plan {
        id
        name
        interval
        interval_unit
        has_ads
      }
    }
  }
}
 
`
export const cancelStripeSubscription = gql`
mutation CancelStripeSubscription($input: cancelStripeSubscriptionInput) {
  cancelStripeSubscription(input: $input) {
    success
    error
    message
  }
}
`

export const authVia = gql`
mutation Mutation($input: authViaInput) {
  authVia(input: $input) {
    success
    error
    message
    code
  }
}
`
export const logoutFromDevice = gql`mutation Mutation($logoutFromDeviceId: Int!) {
  logoutFromDevice(id: $logoutFromDeviceId) {
    success
    error
    message
  }
}
`
export const getConnectedDevices = gql`
query userConnectedDevices {
  userConnectedDevices {
    data {
      id
      name
      device_uuid
      device_type
      isCurrentDevice
    }
  }
}
`
export const GetUserSubscriptionStatus = gql`query GetUserSubscriptionStatus {
  getUserSubscriptionStatus {
    is_active
    success
    error
    message
  }
}`
export const verifyOpt = gql`
mutation AuthViaVerify($input: AuthViaVerifyInput) {
  authViaVerify(input: $input) {
    success
    error
    message
    code
    token
    user {
      id
      email
      phone
    }
  }
}
`

export const logoutFromDevices = gql`
mutation LogoutFromDevice($logoutFromDeviceId: Int!) {
  logoutFromDevice(id: $logoutFromDeviceId) {
    success
    error
    message
  }
}
`
export const logout = gql`
mutation logout {
  revokeAccessToken {
    success
    error
    message
  }
}
`