<template>
  <nav class="w-full md:h-24 h-16 ">
    <div
      class="lg:px-12 px-4 h-full mx-auto flex md:justify-between justify-center items-center sticky z-40 shadow-menu"
    >
      <div class="z-30">
        <div class="flex flex-wrap justify-between items-center">
          <auth-actions />
          <!--end Dropdown menu -->
          <div class="flex justify-center">
            <div class="relative hidden md:block ml-2">
              <!-- This is an example component -->
              <SearchView />
            </div>
          </div>
        </div>
      </div>
      <!-- Logo Here -->
      <div class="md:order-2 logo z-40">
        <router-link to="/" class="">
          <img
            src="@/assets/logo-1.webp"
            class="ml-3"
            alt="Nasan Logo"
            width="100"
            height="100"
          />
        </router-link>
      </div>

      <div class="md:ml-auto hidden md:block">
        <!-- Menu links here -->
        <ul
          id="menu"
          class="hidden fixed top-0 right-0 px-10 py-16 bottom-20 z-30 md:relative md:flex md:p-0 md:bg-transparent md:flex-row lg:-space-x-1 duration-700 divider-x divide-gray-700"
          dir="rtl"
        >
          <li class="flex justify-start items-center">
            <router-link
              to="/categories"
              class="menu-item text-[1.5vw]"
            >
              تصفح التصنيفات</router-link
            >
          </li>

          <li class="flex justify-start items-center">
            <router-link
              to="/brows/channels"
              href="#"
              class="menu-item text-[1.5vw]"
            >
              القنوات
              <small
                class="text-white bg-red-600 rounded-sm px-2 py-1.5 text-xs"
                >بث مباشر</small
              >
            </router-link>
          </li>
        </ul>

        <!-- This is used to open the menu on mobile devices -->
      </div>
    </div>
  </nav>
</template>

<script>
import AuthActions from "./shared/AuthActions.vue";
import SearchView from "./shared/SearchView.vue";
export default {
  components: { SearchView, AuthActions },

  name: "MobileView",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
/* .bg-mobile{
    background-color: rgba(0, 0, 0, 0.1);
  } */

input[type="search"]::placeholder {
  text-align: right;
}
focus:input[type="search"] {
  outline: none;
  border: none;
  outline-width: 0;
}
.shadow-menu {
  background: linear-gradient(to top, rgb(21, 24, 43, 0) 0%, rgb(0, 0, 0, 100));
}

</style>
