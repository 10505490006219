import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { USER } from "@/constants";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes /* eslint-disable */,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
});
// nasna

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.includes("?msisdn=")) {
    window.location.href =`https://zain.nasna.tv?msisdn=${to.query.msisdn}`
  }else if (to.fullPath.includes("?phoneNumber=")){
    window.location.href =`https://sudani.nasna.tv?phoneNumber=${to.query.phoneNumber}`
  }

  const data = localStorage.getItem(USER);
  let user = null;
  if (data) {
    user = JSON.parse(data);

  }
  if (to.meta.auth && user === null) {
    return next({ name: "auth.login", params: {} });
  } else {
    next();
  }
});

export default router;
