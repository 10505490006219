<template>
  <div class="mx-auto mb-2">
    <!-- <carsouel-view /> And  menu  -->
    <HomeLayout :data="true">
      <div>
        <swiper-slider />
      </div>
    </HomeLayout>

    <section v-if="getCollections">
      <div v-for="collection in getCollections" :key="collection.id">
        <!-- default layout for neel -->
        <div class="my-6" v-if="collection.layout === 'neel'">
          <div class="flex md:my-6 my-4 mr-8 md:mr-[51px]" dir="rtl">
            <SeriesHeader :title="collection.name" class="md:mr-0 -mr-4" />
          </div>
          <div class="mx-auto mr-4 md:mr-[51px] my-3" dir="rtl">
            <DefaultSwiper :items="collection.series" />
          </div>
        </div>
        <!-- soba layout  -->
        <div
          class="my-3  py-6"
          v-else-if="collection.layout === 'soba'"
          :style="{
            backgroundImage: `url(${getBackgroundImage(collection.background)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        >
          <div class="text-center flex flex-col gap-2">
            <div class="text-center text-white md:text-[2vw] text-xl">
              {{ collection.name }}
            </div>
            <div class="flex justify-center" v-show="collection.image">
             <img :src="getDescriptionImage(collection.image)" alt="description image">
            </div>
          </div>
          <div class="mx-auto mr-4 md:mr-[51px] my-3" dir="rtl">
            <SeriesCard :items="collection.series" />
          </div>
        </div>
        <!-- tuti layout -->
        <div class="my-4" v-else-if="collection.layout === 'tuti'">
          <div class="flex md:my-6 my-4 mr-8 md:mr-[51px]" dir="rtl">
            <SeriesHeader :title="collection.name" class="md:mr-0 -mr-4" />
          </div>

          <div class="mx-auto mr-4 md:mr-[51px] my-3" dir="rtl">
            <TutiSwiper :items="collection.series" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// store data
import { mapState, mapActions } from "pinia";

import { useSerieStore } from "@/stores/serie";

import HomeLayout from "@/components/HomeLayout.vue";
import SeriesCard from "@/components/cards/SeriesCard.vue";
import SeriesHeader from "@/components/home/SeriesHeader.vue";
import { usehomeStore } from "@/stores/home";
import DefaultSwiper from "@/components/cards/DefaultSwiper.vue";
import TutiSwiper from "@/components/cards/TutiSwiper.vue";
import { S3_BASE_URL } from "@/constants";
import SwiperSlider from "@/components/carousel/SwiperSlider.vue";

export default {
  // data section
  data() {
    return {
      loaded: false,
      title: "",
      page: 1,
      MostWatchedData: false,
      base_url: S3_BASE_URL,
    };
  },
  /**
   * home required components
   *
   */
  components: {
    HomeLayout,
    SeriesCard,
    SeriesHeader,
    DefaultSwiper,
    TutiSwiper,
    SwiperSlider,
  },

  computed: {
    ...mapState(useSerieStore, ["seriesData", "getMostWatched"]),
    ...mapState(usehomeStore, ["getCollections"]),
  },

  methods: {
    ...mapActions(useSerieStore, ["showHomeSeries", "showMostWatched"]),
    ...mapActions(usehomeStore, ["fetchCollections"]),
    getBackgroundImage(image) {
      return `${this.base_url}collections/${image}`;
    },
    getDescriptionImage(image) {
      return `${this.base_url}collections/${image}`;
    },
  },

  async created() {
    this.loaded = true;
    try {
      await Promise.all([
        this.showHomeSeries(1, 10),
        this.fetchCollections(),
        (this.loaded = false),
      ]);
    } catch (error) {
      this.loaded = false;
      console.error("Failed to get portal data", error);
    }
  },
};
</script>
<style scoped>
.shadow {
  box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
  -webkit-box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
  -moz-box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
}

.border-cover {
  border-color: #191a2c;
}
</style>
