<template>
  <div
    dir="rtl"
    class="flex items-center justify-between border-2 p-4 text-white rounded-md md:w-10/12 w-full"
    :class="isSelected == 'daily' ? 'border-basic justify-between' : 'border-gray-700'"
  >
    <div class="flex flex-col gap-1" dir="rtl">
      <div class="md:text-[1.4vw] text-md">{{ "باقة شبكات الموبايل" }}</div>
      <div class="text-gray-600 text-sm">{{ "يمكنك الدفع باستخدام" }}</div>
      <div class="flex space-x2">
        <img src="@/assets/tele/sudani-logo.png" class=" object-contain w-10 mx-2" alt="sudani" />
        <img src="@/assets/tele/zain.png" class=" object-contain w-10 mx-2" alt="zain" />
        <img src="@/assets/tele/mtn.png" class=" object-contain w-10 mx-2" alt="mtn" />
      </div>
    </div>
    <div class="flex flex-col justify-start gap-y-2" dir="ltr">
      <div v-show="isSelected == 'daily'" class=" w-6 h-6 text-center">
        <plan-checked />
      </div>
      <div class="flex flex-col justify-center items-center">
        <strong class="md:text-[1.4vw] text-md">{{ "295" }}</strong>
        <small class="text-gray-600">جنيه/اليوم</small>
      </div>
    </div>
  </div>
</template>

<script>
import PlanChecked from "../svg/PlanChecked.vue";
export default {
  props: ["isSelected", "monthlyPlan"],

  components: {
    PlanChecked,
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
