<template>
  <div class="carsouel h-full absolute top-0 right-0 w-full">
    <div class="carsoule-inner relative h-[28rem] overflow-hidden md:h-[80dvh]">
      <swiper   :autoplay="{ delay: 6000, disableOnInteraction: false, }" :pagination="{ clickable: true, }" :loop="true" :modules="modules" class="mySwiper">
        <swiper-slide v-for="slide in slidersData" :key="slide.id">
          <slider-item :slide="slide" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Import required modules
import { Autoplay,Pagination } from "swiper";

import { useSlidersStore } from "@/stores/sliders";
import { mapActions, mapState } from "pinia";
import SliderItem from "./SliderItem.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    SliderItem,
  },
  setup() {
    return {
      modules: [Autoplay,Pagination],
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      },
    };
  },
  computed: {
    ...mapState(useSlidersStore, ["slidersData"]),
  },
  methods: {
    ...mapActions(useSlidersStore, ["showSlidersData"]),
  },
  async created() {
    this.loaded = true;
    try {

      await this.showSlidersData();
      this.loaded = false;
    } catch (error) {
      console.log(this.error);
      this.loaded = false;
    }
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}




/* Custom pagination styles */
::v-deep .swiper-pagination-bullet {
  background-color: #ffffff !important; /* Default color */
  opacity: 0.5 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  transition: all 0.3s ease !important;
}

::v-deep .swiper-pagination-bullet-active {
  background-color: #9d2890 !important; /* Active color */
  opacity: 1 !important;
  width: 40px !important;
  height: 8px !important;
  border-radius: 25px !important;
}


@media only screen and (max-width: 960px) {
  ::v-deep .swiper-pagination-bullet {
  background-color: #ffffff !important; /* Default color */
  opacity: 0.5 !important;
  width: 6px !important;
  height: 6px !important;
  border-radius: 50% !important;
  transition: all 0.3s ease !important;
}

::v-deep .swiper-pagination-bullet-active {
  background-color: #9d2890 !important; /* Active color */
  opacity: 1 !important;
  width: 30px !important;
  height: 6px !important;
  border-radius: 25px !important;
}

}
</style>