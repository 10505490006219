import HomeView from '@/views/main/HomeView.vue'
import WatchView from '@/views/main/WatchView.vue'
import WatchNow from '@/views/main/WatchNow.vue'
import CategoriesView from '@/views/main/CategoriesView.vue'
import TestSlider from '@/views/TestSlider.vue.vue'

export const mainRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/watch/:id',
    name: 'watch',
    component: WatchView,
    meta: {
      footer: true,
      auth: true
    }
  },
  {
    path: '/watch/now/:id',
    name: 'watch.now',
    component: WatchNow,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/test/slider',
    name: 'test.slider',
    component: TestSlider,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoriesView,
    meta: {
      footer: true,
      auth: false
    }
  },
  
]