<template>
  <div
    dir="rtl"
    class="flex items-center justify-between border-2 p-4 text-white rounded-md md:w-10/12 w-full"
    :class="
      isSelected == 'monthly'
        ? 'border-basic justify-between'
        : 'border-gray-700'
    "
  >
    <div class="flex flex-col gap-2" dir="rtl">
      <div class="md:text-[1.4vw] text-md">
        {{ price?.metadata?.name }}
        <span class="text-gray-600" v-show="price?.metadata?.has_adds">{{
          "مع إعلانات"
        }}</span>
        <small
          class="bg-basic p-1.5 rounded-sm text-white text-sm"
          v-show="price?.metadata?.popular"
          >الأكثر شهرة</small
        >
      </div>
      <div class="text-gray-700 text-sm">{{ "يمكنك الدفع باستخدام" }}</div>
      <payment-view class="my-1" />
    </div>
    <div class="flex flex-col justify-start gap-y-2" dir="ltr">
      <div
        v-show="isSelected == price?.metadata?.name"
        class="w-6 h-6 text-center ml-6"
      >
        <plan-checked />
      </div>
      <div class="flex flex-col justify-center items-center">
        <strong class="md:text-[1.4vw] text-md">{{
          price?.unit_amount
        }}</strong>
        <small class="text-gray-600">{{getCurrencyInArabic(price.currency)}}/الشهر</small>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentView from "@/components/payments/PaymentView.vue";
import PlanChecked from "../svg/PlanChecked.vue";
export default {
  props: ["isSelected", "price"],
  data() {
    return {
      currency: ["USD", "AED", "EGP", "QAR", "SAR"],
    };
  },
  components: {
    PaymentView,
    PlanChecked,
  },
  created() {},
 
  methods: {
    getCurrencyInArabic(currencyCode) {

      const currencyMap = {
        USD: "دولار ",
        AED: "درهم ",
        EGP: "جنيه ",
        QAR: "ريال ",
        SAR: "ريال ",
      };
      const code = currencyCode.toUpperCase();
      return currencyMap[code] || currencyMap["USD"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
