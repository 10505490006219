<template>
  <div class="h-60 md:h-96  flex justify-center mt-4 mx-4" >
    
    <CarosuelAuth :interval="6000" controls indicators/>

  </div>
</template>
<script>
import CarosuelAuth from '../carousel/CarosuelAuth.vue';



export default {
  components: {
    CarosuelAuth
},
 data() {
    return {
   sliders:null
    };
  },
 

  methods: {
    //methods
  }
};
</script>

<style >
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
