import { collections } from '@/Gql/home';
import apolloClient from '@/plugins/apollo';
import { defineStore } from 'pinia';

export const usehomeStore = defineStore('home', {
    state: () => ({
        collections: []
    }),
    getters: {
        getCollections: (state) => state.collections
    },
    actions: {
        async fetchCollections() {
            await apolloClient
                .query({
                    query: collections,

                    fetchPolicy: "cache-first",

                }).then(async response => {
                    this.collections = response.data.getCollections.data;
                })
                .catch(error => {
                    this.error = error
                })
        },
    },
});
