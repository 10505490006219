<template>
  <div
    class="md:container mx-auto flex flex-col items-center font-Almarai relative overflow-x-hidden"
  >
    <LogoSvg class="absolute" />

    <div class="flex justify-center items-center w-3/12 mt-4 h-36">
      <img src="@/assets/logo-1.webp" alt="logo" class="w-28" />
    </div>
    <!-- Main Content -->
    <div
      class="flex flex-col justify-center items-center gap-y-6 bg-transparent rounded-md md:mx-auto mx-2 md:w-6/12 w-full px-2"
    >
      <div class="my-2 flex flex-col justify-center items-center w-full">
        <h1 class="text-gray-600 text-md my-2 font-Almarai">خطوة 1 من 2</h1>
        <h1 class="md:text-2xl font-bold text-white my-2 font-Almarai">
          اختر الباقة المناسبة
        </h1>

        <!-- stripe plans -->
        <div
          class="flex flex-col col-span-5 gap-y-4 justify-center items-center py-4 rounded-md w-full"
        >
          <tele-come
            :isSelected="isSelected"
            class="cursor-pointer"
            @click="selectPlan('daily')"
            v-show="countryCode"
          />
          <!-- monthly  -->

          <monthly-option
            v-for="price in prices"
            :key="price.id"
            @click="selectPlan(price.metadata.name)"
            :isSelected="isSelected"
            class="cursor-pointer"
            :price="price"
          />
        </div>

        <!-- end of monthly -->

        <!-- end of stripe plans -->
      </div>
      <div class="flex flex-col items-center gap-y-2 justify-center w-full mx-auto my-3">
        <button
          @click.prevent="redirectUserToProvider"
          class="inline-flex justify-center items-center gap-2 md:w-10/12 w-full bg-[#9d2890] text-gray-100 rounded-md py-3"
        >
          <span> متابعة </span>
        </button>
        <button
          @click.prevent="$router.go(-1)"
          class="inline-flex justify-center items-center gap-2 md:w-10/12 w-full border border-basic text-gray-100 rounded-md py-3"
        >
          <span> رجوع </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import animationData from "@/assets/loader.json";
import LogoSvg from "@/components/svg/LogoSvg.vue";

import MonthlyOption from "@/components/payments/MonthlyOption.vue";
import { loadStripe } from "@stripe/stripe-js";
import TeleCome from "@/components/payments/TeleCome.vue";
import { HTTP } from "@/http-common";

export default {
  components: {
    LogoSvg,
    MonthlyOption,
    TeleCome,
  },
  data() {
    return {
      toast: useToast(),
      loaded: false,
      lottieOptions: {
        animationData,
        loop: true,
        autoplay: true,
      },
      jsonFile: animationData,

      prices: [], // List of prices fetched from the backend

      isMobile: false,
      selectedProvider: null,
      StripePlan: null,
      isSelected: null,
      activeTab: "mobile",
      monthlyPlan: null,
      yearlyPlan: null,
      countryCode:false
    };
  },
  computed: {
    isCodeFull() {
      return this.code.length === 4; // Assuming OTP is 6 digits long
    },
  },
  methods: {
    selectPlan(name) {
      this.isSelected = name;
      console.log("selected", this.isSelected);
    },
    redirectUserToProvider() {
      if (!this.isSelected) {
        this.toast.error("اختر الباقة المناسبة");
        return;
      }
      if (this.isSelected == "daily") {
        this.$router.push({ name: "auth.operators", params: {} });
        return;
      } else {
        const selectedPrice = this.prices.find(
          (price) => price.metadata.name === this.isSelected
        );
        localStorage.setItem("selectedPrice", JSON.stringify(selectedPrice));
        this.$router.push({ name: "auth.strip", params: {} });
      }
    },
    async fetchPrices() {
      console.log("fetching prices");
      try {
        // Fetch prices for the selected currency
        const response = await fetch("https://api.nasna.tv/api/stripe/prices");
        this.prices = await response.json();
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    },
  },
  async created(){
    await HTTP.get("https://ipapi.co/json")
      .then((res) => {
        if(res.data.country=="SD"){
          this.countryCode = true;
        }
       
      })
      .catch((error) => {
        this.countryCode = "AE";
        console.log(error);
      });
  },
  async mounted() {
    // Initialize Stripe
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIIC_KEY);
    // Fetch prices for the default currency
    await this.fetchPrices();
   
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped></style>
