<template>
  <div
    class="relative carousel-item h-full object-cover z-0 bg-center bg-cover w-[96%] cursor-pointer border-b border-gray-800"
    @click="$router.push({ name: 'watch', params: { id: firstEposide.id } })"
    :style="{
      backgroundImage: `url(${image(asset)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <div class="">
      <div
        class=" hidden md:block absolute inset-y-0 right-0 w-1/2 h-full right-shadow text-white"
      ></div>
      <div class="absolute bottom-0 inset-x-0 h-44 bottom-shadow"></div>

      <div
      class="absolute md:bottom-[4rem] bottom-0 right-1/2 md:right-0 md:-translate-x-0 translate-x-1/2 my-4  md:w-[600px] flex flex-col justify-center items-center md:items-start"
      dir="rtl"
      >
      <div
          class="flex md:justify-start justify-center items-center w-36 h-36"
        >
          <img
            :src="getLogo(asset)"
            alt="logo"
            class="object-fit"
            style="object-fit: contain !important"
          />
        </div>
      <div class="hidden md:flex justify-start space-x-1 my-1" dir="">

          <div
            class="py-2 px-6 tag-bg border border-gray-300 rounded-[25px] text-white text-md font-medium mx-1"
            v-for="tag in asset.tags"
            :key="tag.id"
            dir="rtl"
          >
            {{ tag.name }}
          </div>
        </div>
        <div
          class="md:hidden text-white space-x-1 flex flex-row justify-center items-center"
        >
          <div
            class="mx-1 flex justify-center items-center"
            v-for="(tag, index) in asset.tags"
            :key="tag.id"
            dir="rtl"
          >
            <span class="mx-2"> {{ tag.name }}</span>
            <span
              v-if="asset.tags?.length > 1 && index < asset.tags.length - 1"
              class="text-4xl font-bold"
            >
              {{ "." }}
            </span>
          </div>
        </div>
        <p
          class="hidden md:block text-white my-3 text[1vw] font-medium leading-6 z-20 md:max-w-lg max-w-sm text-right"
          dir="rtl"
        >
          {{ asset.desc ? asset.desc : "" }}
        </p>
        <div class="flex md:justify-end justify-center items-center my-3" >
          <router-link :to="`/watch/${firstEposide.id}`" class="">
            <button
              v-show="asset.desc != 'null'"
              type="button"
              class="bg-white rounded-[30px] md:px-6 md:py-2 px-3 py-2 text-black font-semibold text-center inline-flex items-center text-xl mt-2 z-20 btn-top text-md lg:text-lg"

            >
              {{ "شاهد الان" }}
              <play-svg />

            </button>
          </router-link>
          <span> </span>
        </div>
      </div>
      <div
        class="hidden md:block absolute bottom-0 md:right-0 text-white border-gray-100 justify-center items-center gap-3 "
      >
        <div
          class="border-l border-gray-100 py-3 px-6 max-w-md md:text-2xl text-sm font-medium"
        >
          الموسم الأول
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { S3_BASE_URL } from "@/constants";
import PlaySvg from "../svg/PlaySvg.vue";

export default {
  props: ["asset", "firstEposide"],
  components: { PlaySvg },
  data() {
    return {
      type: "series",
      S3_BASE_URL: S3_BASE_URL,
      share: false,
      loaded: false,
    };
  },

  methods: {
    image(asset) {
      if (asset.cover) {
        return `${S3_BASE_URL}${this.type}/${asset.cover}`;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "default.webp");
      }
    },
    getLogo(asset) {
      if (asset?.logo) {
        return `${this.S3_BASE_URL}series/${asset.logo}`;
      }
    },
    /////end of image
    async shareApi() {
      const title = window.document.title;
      const url = window.document.location.href;
      const text = this.asset.desc;
      const resultPara = null;
      const shareData = {
        title: title,
        text: text,
        url: url,
      };
      if (navigator.share) {
        try {
          await navigator.share(shareData);
          resultPara.textContent = "MDN shared successfully";
        } catch (error) {
          resultPara.textContent = `Error: ${error}`;
        }
      } else {
        return (this.share = true);
      }
    },
    close() {
      return (this.share = false);
    },
  },
  creatd() {
    console.log(this.asset);
  },
};
</script>

<style scoped>
.btn-bg {
  background-color: #9d2890;
}
*:focus-visible {
  outline: none;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
