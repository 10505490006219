<template>
  <div class="h-screen flex flex-col items-center relative">
    <LogoSvg />

    <div
      class="flex justify-center items-center w-3/12 absolute top-1 left-1/2 -translate-x-1/2"
    >
      <img src="@/assets/logo-1.webp" alt="logo" class="w-24" />
    </div>
    <!-- Main Content -->
    <div
      class="px-4 p-1 flex flex-col justify-center gap-y-6 bg-transparent lg:p-2 rounded-md place-items-center absolute top-36"
    >
      <div v-show="loaded">
        <div class="mt-4">
          <Vue3Lottie
            :animationData="jsonFile"
            :height="100"
            :width="100"
            class="rounded-full"
          />
        </div>
        <p class="text-white text-lg font-bold text-center mt-3">يجري التحقق</p>
      </div>

      <div class="" v-show="!loaded">
        <div class="flex flex-row justify-center my-1.5">
          <div class="md:auto text-center">
            <p
              class="text-white pb-0.5 px-2 md:text-6xl text-xl font-extrabold"
            >
              أدخل رمز OTP
            </p>
          </div>
        </div>
        <div class="my-2 text-center flex justify-center">
          <p class="text-gray-400 text-sm max-w-md text-center">
            لقد أرسلنا رسالة تحتوي على رمز التفعيل إلى {{ PhoneNumber }}
          </p>
        </div>
      </div>
      <div class="my-1" v-show="!loaded">
        <div class="flex justify-center items-center">
          <MazInputCode
            v-model="code"
            size="md"
            :error="error"
            class="text-white"
          />
        </div>
        <div
          class="flex flex-col justify-center items-center gap-3 my-3 relative z-50"
        >
          <div class="text-center mt-2"></div>
          <button
            type="button"
            class="md:w-96 w-full bg-[#9d2890] py-3 px-6 rounded-md inline-flex justify-center items-center gap-2 text-white cursor-pointer relative z-50"
            @click.prevent="submitCode"
            :disabled="!isCodeFull || loaded"
          >
            <!-- :disabled="!isCodeFull || loaded" -->
            <span v-if="!loaded" class="text-lg font-semibold">تأكيد</span>
            <div role="status" v-show="loaded">
              <loader-svg />
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
      <div class="">
        <small class="text-gray-500 text-center"
          >ليس لديك وصول لهذا الرقم؟
          <span class="text-blue-800 cursor-pointer" @click="$router.go(-1)">تغيير</span></small
        >
      </div>
    </div>
  </div>
</template>

<script>
import MazInputCode from "maz-ui/components/MazInputCode";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
import { Vue3Lottie } from "vue3-lottie";
import animationData from "@/assets/loader.json";
import LogoSvg from "@/components/svg/LogoSvg.vue";
import { USERLOGINID } from "@/constants";
export default {
  components: {
    MazInputCode,
    Vue3Lottie,
    LogoSvg,
  },
  data() {
    return {
      code: "",
      showMessage: false, // Controls visibility of the alert message
      canResend: true, // Whether the user can resend the OTP
      time: 60, // 2 minutes in seconds
      size: "lg",
      toast: useToast(),
      loaded: false,
      lottieOptions: {
        animationData,
        loop: true,
        autoplay: true,
      },
      jsonFile: animationData,
      error: false,
      PhoneNumber: localStorage.getItem(USERLOGINID),
    };
  },
  computed: {
    isCodeFull() {
      return this.code.length === 4; // Assuming OTP is 6 digits long
    },
  },
  methods: {
    ...mapActions(useAuthStore, [
      "verifyOptAction",
      "subscribeWithPhone",
      "setData",
    ]),

    async submitCode() {
      this.loaded = true;
      const id = localStorage.getItem(USERLOGINID);
      await this.verifyOptAction(id, this.code, "phone").then((response) => {
        if (response.data.authViaVerify.success) {
          this.$router.push({ name: "otp.success.page" });

          this.loaded = false;
        } else {
          this.loaded = false;
          this.toast.error("رمز التأكيد غير صحيح");
        }
      });
    },
    async resendOTP() {
      this.time = 60; // Reset timer
      this.startTimer();
      // Implement the logic to resend the OTP
      await this.subscribeWithPhone(
        localStorage.getItem("userPhoneNumber")
      ).then((response) => {
        if (response.data.success) {
          this.toast.success("تم إرسال رمز التأكيد");
        } else {
          this.toast.error("حدث خطأ ما، حاول مرة أخرى");
        }
      });
    },

    startTimer() {
      const timerInterval = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(timerInterval);
          // Timer finished logic here
        }
      }, 1000);
    },
    formatTime() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    closeAlert() {
      this.showMessage = false;
    },
  },
  created() {
    this.startTimer();
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-basic {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}

.font-small {
  font-size: 16px;
}
</style>
