<template>
  <div class="flex mt-2 text-white md:text-[2vw] text-md">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "ناسنا",
    },
  },
};
</script>

<style></style>
