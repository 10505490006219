<template>
  <div class=" hidden md:block">
    <slot />
    <div class="flex  justify-center space-x-2 -mt-1" v-if="isActive">
      <img
            src="@/assets/avatar.webp"
            class="rounded-full w-12 h-12 cursor-pointer "
            alt="avatar"
            @click="$router.push({ name: 'auth.profile' })"
          />
          
    </div>
    <div class="flex  justify-center space-x-2 -mt-1" v-else>
      
        <button
        @click="$router.push({ name: 'auth.subscriptions' })"
          class="sub-btn-bg text-white text-center rounded-md  md:py-2.5 px-8 py-2 text-[1.5vw] "
        >
          اشترك الان
        </button>
      
      <router-link to="/auth/login">
        <button
          class="border border-color text-white text-center rounded-md  md:py-2.5 px-8 py-2 text-[1.5vw] truncate"
        >
          تسجيل دخول
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {ISACTIVE} from '@/constants'
import { useAuthStore } from '@/stores/auth'
import { mapActions } from 'pinia'
import { useToast } from 'vue-toastification'

export default {
  components: {
    // SpinnerView,
  },
  data() {
    return {
      user: false,
      isActive: false,
      isDone: false,
      toast:useToast()
    };
  },
   mounted() {
     this.checkIsActiveAction()
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line
    isActive(newValue, oldValue) {},
  },
  methods: {

     checkIsActiveAction() {
      return (this.isActive = localStorage.getItem(ISACTIVE))
    },
    ...mapActions(useAuthStore, ["logout"]),
    
  
    logoutAction() {
      try{
        this.logout().then((response)=>{
          if(response.data.revokeAccessToken?.success){
            this.isActive = false
            this.toast.info('تم تسجيل الخروج')
            this.$router.push({ name: 'home' })
           this.isActive=false
          }
        })
      }catch(error){
        console.log(error)
      }
        
        
    }
  } //end of methods
}
</script>

<style></style>
