import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getEposides, getFiltter, getEposide } from "@/Gql/episodes";
export const useEposidesStore = defineStore("eposides", {
    state: () => {
        return {
            eposides: Array,
            meta: Object,
            asset: Object,
            oldEps:Array,
            loading:false
        }
    },
    getters: {
        eposidesData: (state) => state.eposides,
        eposidesMetaData: (state) => state.meta,
        assetData: (state) => state.asset,
        epo: (state) => state.oldEps,
        loadingStatus:(state)=>state.loading,
        getError:(state)=>state.error

    },
    actions: {
        async showEposidesData(page = 1, limit = 6) {
                this.oldEps=this.eposides
            await apolloClient
                .query({
                    query: getEposides,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides=[...res?.data?.getEposides?.data?.filter((eposide)=>eposide.published===true)],
                    this.meta = res.data?.getEposides?.meta
                   
                })
                .catch(error => {
                    this.error = error
                })
                if(!this.eposides)return
                this.eposides?.slice().push(this.oldEps)
        },

        async showEposidesNextData(page = 1, limit = 6) {
                this.loading=true;
            await apolloClient
                .query({
                    query: getEposides,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides.push(...res.data.getEposides.data.filter((eposide)=>eposide.published===true)),
                    this.meta = res.data.getEposides.meta
                   
                })
                .catch(error => {
                    this.error = error
                })
                this.loading=false
        },

        async filtterData(page = 1, limit = 20, text = "",genreid ,type="eposides",order =null,orderBy=null) {
            console.log(page+"page",limit ,text,genreid,type)
            await apolloClient
                .query({
                    query: getFiltter,
                    variables: {
                        page: page, limit: limit, text: text, genreId: genreid,type:"eposides" , order: order, orderBy: orderBy,

                    },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides = res.data.filters.data.filter((eposide)=>eposide.published===true),
                    this.meta = res.data.filters.meta
                })
                .catch(error => {
                    this.error = error
                })
        },

    //  

    async fetchAssets(id) {
        this.asset = null;
        await apolloClient
            .query({
                query: getEposide,
                variables: { getEposideId: id },
                fetchPolicy: "cache-first",
            }).then(res => {
                // Create a new object with all the original data
               this.handleNullData(res)
            })
            .catch(error => {
                this.error = error;
                console.log(error);
            });
    },

    handleNullData(res){
        if(res.data.getEposide == null){
            return this.asset = null
        }else{
            this.asset = {
                ...res.data.getEposide,
                serie: {
                    ...res?.data?.getEposide?.serie,
                    // Filter out unpublished episodes
                    eposides: res?.data?.getEposide?.serie?.eposides?.filter(episode => episode.published)
                    
                }
                

               
            };
        }
    }
    

      
    }
})