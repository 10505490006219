import { authRoutes } from './routes/authRoutes'
import { mainRoutes } from './routes/mainRoutes'
import { browsRoutes } from './routes/browsRoutes'
import { responseRoutes } from './routes/responseRoutes'
import { tvRoutes } from './routes/tvRoutes'
import { notFoundRoutes } from './routes/notFoundRoutes'
import { authRoutesGlobal } from './routes/authRoutesGlobal'
export const routes = [
  ...mainRoutes,
  ...browsRoutes,
  ...responseRoutes,
  ...tvRoutes,
  ...authRoutes,
  ...authRoutesGlobal,
  ...notFoundRoutes
]