import gql from "graphql-tag";

export const getLives = gql`
query Query($page: Int!, $limit: Int!) {
              getLives(page: $page, limit: $limit) {
                
                  data {
                  id
                  key
                  streaming_url
                  cover
                  desc
                  published
                  createdAt
                  updatedAt
                  }
              }
              }`

//single live data
export const getlive=gql`
    query Query($getLiveId: ID!) {
        getLive(id: $getLiveId) {
        id
        key
        streaming_url
        cover
        desc
        published
        }
        }`