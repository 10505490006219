<template>
  <div
    v-if="installer"
    class="md:hidden install-prompt fixed -inset-x-0.5 z-50 bottom-[80px] max-w-[960px] w-full text-sm rounded-md p-4 flex justify-between items-center animate-in slide-in-from-left duration-700 bg-white"
    dir="rtl"
  >
    <div class="flex items-center space-x-2">
      <p class="text-wrap text-size text-black text-left mx-2">
        احصل على التطبيق
      </p>
    </div>
    <div class="flex items-center space-x-3">
      <button
        @click="installApp"
        class="py-2 px-4 bg-basic text-white rounded-md border border-white shadow-inner text-size mx-2"
      >
        تثبيت
      </button>
      <div
        class="py-1 px-4 cursor-pointer border border-basic rounded-md text-basic"
        @click="cancelInstallation"
      >
        إخفاء
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      installer: false, // Controls the visibility of the install prompt
    };
  },
  mounted() {
    this.checkInstallPrompt();
  },
  methods: {
    checkInstallPrompt() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      // Check if the prompt was hidden recently
      const lastHidden = localStorage.getItem("installPromptHiddenAt");
      const cooldown = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

      if (lastHidden && Date.now() - lastHidden < cooldown) {
        this.installer = false; // Do not show the prompt
        return;
      }

      // Show the prompt for mobile devices only
      this.installer = isMobile;
    },
    cancelInstallation() {
      // Hide the installation prompt and save the current timestamp
      localStorage.setItem("installPromptHiddenAt", Date.now());
      this.installer = false;
    },
    installApp() {
      // Redirect to the Google Play Store link
      window.location.href = "https://onelink.to/vc46gz";
    },
  },
};
</script>

<style scoped>
.install-prompt {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-size {
  line-height: normal;
}

@media (min-width: 320px) and (max-width: 360px) {
  .text-size {
    font-size: 11px;
    line-height: normal;
  }
}
@media (min-width: 360px) and (max-width: 960) {
  .text-size {
    font-size: 14px;
  }
}
@media (min-width: 960px) {
  .text-size {
    font-size: 16px;
  }
}
</style>
