<template>
  <div class="h-screen flex flex-col items-center   font-Almarai overflow-x-hidden">

    <LogoSvg />

    <div
      class="flex justify-center items-center w-3/12 absolute top-0 left-1/2 -translate-x-1/2 h-36"
    >
      <img src="@/assets/logo-1.webp" alt="logo" class="w-36" />
    </div>
    <!-- Main Content -->
    <div
      class=" flex flex-col justify-center items-center gap-y-6 bg-transparent  rounded-md absolute top-36  mx-auto"
    >
      <div class="my-2">
        <h1 class="md:text-2xl font-bold text-white my-2 font-Almarai text-center">
          التسجيل أو الدخول
        </h1>
        <h3 class="text-gray-500 text-md">
          ادخل رقم هاتفك للمتابعة، سيتم إرسال رمز تحقق للتأكيد
        </h3>
      </div>
      <div class="">
        <phone-number />
       
      </div>
      <small class="text-gray-500 text-center">أو اختر الاستمرار عن طريق</small>
      <div class="flex justify-center items-center space-x-3 text-white">
        <div
          class="cursor-pointer"
          @click="$router.push({ name: 'auth.login.email', params: {} })"
        >
          <img src="@/assets/email.png" alt="phone" />
        </div>
        <div
          class="cursor-pointer"
          @click="redirectTo('google')"
        >
          <img src="@/assets/Google.svg" alt="google-email" />
        </div>
        <div
          class="cursor-pointer"
          @click="redirectTo('apple')"
        >
          <img src="@/assets/Apple.svg" alt="apple" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
import animationData from "@/assets/loader.json";
import LogoSvg from "@/components/svg/LogoSvg.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import axios from 'axios'
export default {
  components: {
    LogoSvg,
    PhoneNumber,
  },
  data() {
    return {
      code: "",
      showMessage: false, // Controls visibility of the alert message
      canResend: true, // Whether the user can resend the OTP
      time: 60, // 2 minutes in seconds
      size: "lg",
      toast: useToast(),
      loaded: false,
      lottieOptions: {
        animationData,
        loop: true,
        autoplay: true,
      },
      jsonFile: animationData,
      error: false,
    };
  },
  computed: {
    isCodeFull() {
      return this.code.length === 4; // Assuming OTP is 6 digits long
    },
  },
  methods: {
    ...mapActions(useAuthStore, [
      "verifyTvPinCode",
      "subscribeWithPhone",
      "setData",
    ]),
    redirectTo(provider){
      const url = `https://api.nasna.tv/api/auth/${provider}/redirect`;
      axios.get(url).then((resposne)=>{
        if(resposne.data.success){
          window.location.href = resposne.data.redirectURL;
        }else{
          console.log(" WENT RONG",resposne)
        }
      }).catch((error)=>{
        console.log(error)
      })
    },
    async submitCode() {
      this.loaded = true;
      const user = JSON.parse(localStorage.getItem("user"));
     
      await this.verifyTvPinCode(user.id, this.code).then((response) => {
        if (response.data.acceptTVCode.success) {
          this.$router.push({ name: "otp.success" });

          this.loaded = false;
        } else {
          this.loaded = false;
          this.toast.error("رمز التأكيد غير صحيح");
        }
      });
    },
    async resendOTP() {
      this.time = 60; // Reset timer
      this.startTimer();
      // Implement the logic to resend the OTP
      await this.subscribeWithPhone(
        localStorage.getItem("userPhoneNumber")
      ).then((response) => {
        if (response.data.success) {
          this.toast.success("تم إرسال رمز التأكيد");
        } else {
          this.toast.error("حدث خطأ ما، حاول مرة أخرى");
        }
      });
    },

    startTimer() {
      const timerInterval = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(timerInterval);
          // Timer finished logic here
        }
      }, 1000);
    },
    formatTime() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    closeAlert() {
      this.showMessage = false;
    },
  },
  created() {
    this.startTimer();
  },
};
</script>

<style scoped>


</style>
