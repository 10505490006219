import EmailLogin from "@/views/auth/EmailLogin.vue";
import EmailOtp from "@/views/auth/EmailOtp.vue";
import OperatorsOptions from "@/views/auth/OperatorsOptions.vue";
import PhoneLogin from "@/views/auth/PhoneLogin.vue";
import PhoneOtp from "@/views/auth/PhoneOtp.vue";
import PlansOptions from "@/views/auth/PlansOptions.vue";
import ProfileStatus from "@/views/auth/ProfileStatus.vue";
import StripeView from "@/views/auth/StripeView.vue";
import SuccessOk from "@/views/auth/SuccessOk.vue";
import SuccessPage from "@/views/auth/SuccessPage.vue";
import CallBack from "@/views/response/CallBack.vue";

export const authRoutesGlobal = [

    {
        path: '/auth/login',
        name: 'auth.login',
        component: PhoneLogin,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/login/email',
        name: 'auth.login.email',
        component: EmailLogin,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/operators/subscriptions',
        name: 'auth.operators',
        component: OperatorsOptions,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/subscriptions/options',
        name: 'auth.subscriptions',
        component: PlansOptions,
        meta: {
            footer: false,
            auth: true
        },
    },
    {
        path: '/auth/subscriptions/stripe',
        name: 'auth.strip',
        component: StripeView,
        meta: {
            footer: false,
            auth: true
        },
    },
    {//auth/stripe/check-payment-intent
        path: '/auth/stripe/check-payment-intent',
        name: 'auth.stripe.payment',
        component: SuccessOk,
        meta: {
            footer: false,
            auth: false
        },
    },
     {
        path: '/auth/phone/check-otp',
        name: 'auth.otp.phone',
        component: PhoneOtp,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/email/check-otp',
        name: 'auth.otp.email',
        component: EmailOtp,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/success/page',
        name: 'otp.success.page',
        component: SuccessPage,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/:provider/callback',
        name: 'auth.provider.callback',
        component: CallBack,
        meta: {
            footer: false,
            auth: false
        },
    },
    {
        path: '/auth/profile',
        name: 'auth.profile',
        component: ProfileStatus,
        meta: {
            footer: false,
            auth: true
        }
    }


]


