import OtpCheck from '@/views/tv/OtpCheck.vue'
import TVSuccess from '@/views/tv/TVSuccess.vue'

export const tvRoutes = [
  {
    path: '/login/tv',
    name: 'otp.check',
    component: OtpCheck,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/user/otp-check/success',
    name: 'otp.success',
    component: TVSuccess
  }
]