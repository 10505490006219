<template>
  <div class="flex justify-center rounded-lg mx-2 mt-2 md:mt-6">
    <div class="md:w-5/12">
      <div
        class="container mx-auto px-4 p-1 bg-basic-dark shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center"
      >
        <auth-carsouel />
        <div class="">
          <h5
            dir="rtl"
            class="my-3 text-md text-lg text-white font-bold font-Almarai text-center"
          >
            قم بالدخول واستمتع بمشاهدة البرامج والقنوات بلا حدود
          </h5>
          <!-- <div  class="border border-b-2 border-pink-500  mx-2 mx-auto mt-1 w-36"></div> -->

          <div class="m-3 relative md:w-96 mx-auto" dir="rtl">
            <label for="mobile" class="text-white"> رقم الهاتف</label>
            <phone-svg />
            <input
              type="number"
              class="bg-gray-900 border border-color focus:ring-pink-500 focus:border-pink-500 text-gray-900 text-sm rounded-sm w-full my-1"
              v-model="phone"
              placeholder="01xxxxxxxx"
              required
            />
          </div>

          <div class="flex justify-center my-6">
            <button
              type="submit"
              class="text-white focus:ring-0 focus:outline-none focus:ring-pink-300 rounded-lg text-md md:w-96 px-5 py-2.5 text-center btn-color cursor-pointer w-full absloute z-20"
              @click="login"
            >
              !دخول الان
            </button>
          </div>

          <div class="text-white hidden" dir="rtl">
            <p
              class="text-center font-small m-2 font-Almarai mt-2"
              style="font-size: 11px; line-height: 15px"
            >
              قيمة الاشتراك اليومي لكل المقاطع والبرامج 120 جنيه فقط شاملة الضريبة تجدد
              تلقائيا عبر شبكة سوداني
            </p>
          </div>
          <!-- hello -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthCarsouel from "@/components/shared/AuthCarsouel.vue";
import PhoneSvg from "@/components/svg/PhoneSvg";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";

export default {
  components: {
    AuthCarsouel,
    PhoneSvg,
  },
  data() {
    return {
      phone: "",
      toast: useToast(),
      error: false,
      errorNotSubscribed: false,
      message: "رقم الهاتف غير صحيح",
      messageNotSuscribed: "أنت غير مشترك  قم بالإشتراك",
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["checkSubescriptionStatus"]),
    async login() {
      var number;

      if (this.phone.toString().startsWith("2491")) {
        number = this.phone.toString().substring(4);
      } else if (this.phone.toString().startsWith("002491")) {
        number = this.phone.toString().substring(6);
      } else if (this.phone.toString().startsWith("01")) {
        number = this.phone.toString().substring(2);
      } else if (this.phone.toString().startsWith("1")) {
        number = this.phone.toString().substring(1);
      } else {
        return (this.error = true);
      }

      // subscribe end point for getting the url
      if (number) {
        await this.checkSubescriptionStatus(number).then((response) =>
          this.redirect(response)
        );
      }
    }, //end of subscrbtion method
    async validatePhoneNumber() {
      var validated = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      var validated2 = /^\(?(\d{4})\)?[- ]?(\d{4})[- ]?(\d{4})$/;

      var userNumber = validated.test(this.phone.toString());
      var userNumber2 = validated2.test(this.phone.toString());

      if (userNumber || userNumber2) {
        await this.login();
      } else {
        this.toast.error(this.message);
      }
    },
    close() {
      return (this.error = false);
    },
    redirect(response) {
      console.log(response.data);
      if (
        response.data.status &&
        response.data.responseMessage == "the subscriber is active"
      ) {
        this.$router.push({ name: "home" });
        this.toast.success("تم تسجيل الدخول");
      } else if (
        response.data.responseMessage == "this MSISDN is not subscribed to the service" ||
        response.data.status == false
      ) {
        setTimeout(() => {
          this.$router.push("/subscribe/headerinerchment");
        }, 2000);
        this.toast.error(this.messageNotSuscribed);
      } else if (!response.data.success) {
        setTimeout(() => {
          this.$router.push("/subscribe/headerinerchment");
        }, 2000);
      } else {
        this.$router.push({ name: "failed" });
      }
    },
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}
.xxsm {
  width: 255px;
}
.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-basic {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}
.font-small {
  font-size: 16px;
}
</style>
