<template>
  <div
    class="rounded-xl h-full w-full md:border-[6px]  md:border-transparent hover:border-white focus:border-white focusable mx-0.5"
    tabindex="0"
    @keydown.enter="handleEnter"
  >

    <router-link :to="`/watch/now/${item.id}`" class="">
      <img
        ref="seriesImage"
        class="swiper-lazy w-full rounded-md"
        :src="defaultImage"
        :data-src="image(item)"
        width="100"
        height="100"
        :alt="item.title"
        style="object-fit: contain !important; "
      />
    </router-link>
  </div>
</template>

<script>
import { S3_BASE_URL } from "@/constants";

export default {
  props: ["item"],
  data() {
    return {
      type: "series",
      S3_BASE_URL: S3_BASE_URL,
      defaultImage: require("@/assets/placeholder.webp"),

      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust as needed
    });

    this.observer.observe(this.$refs.seriesImage);
  },
  methods: {
    formateSyntax(count) {
      if (count == 1) {
        return 'حلقة واحدة'
      } else if (count == 2) {
        return 'حلقتان'
      } else if (count > 2 && count < 11) {
        return `${count} حلقات`
      } else {
        return `${count} حلقة`
      }
    },
    image(item) {
      if (item.image) {
        return `${S3_BASE_URL}${this.type}/${item.image}`;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "default.webp");
      }
    },
    handleEnter(event) {
      if (event.key === "Enter") {
        this.$router.push({ name: "watch.now", params: { id: this.item.id } });
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style></style>
