<template>
  <div class="flex -mr-2">
    <img src="@/assets/payments/master-card.png" class="mx-1" alt="master-card" />
    <img src="@/assets/payments/Visa.png" class="mx-1" alt="visa" />
    <img src="@/assets/payments/g-pay.png" class="mx-1" alt="google pay" />
    <img src="@/assets/payments/apple-pay.png" class="mx-1" alt="apple pay" />
  </div>
</template>

<script>
export default {
  name: "PaymentView",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
