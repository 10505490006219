import gql from "graphql-tag";

export const getFiltter = gql`
query Query($page: Int!, $limit: Int!, $text: String, $genreId: String, $type: String, $order: String, $orderBy: String) {
  filters(page: $page, limit: $limit, text: $text, genreId: $genreId, type: $type, order: $order, orderBy: $orderBy) {

data {
... on Serie {
id
title
image
cover
eposides_count
published
createdAt
label
}
}
meta {
total
per_page
current_page
last_page
first_page
first_page_url
last_page_url
next_page_url
previous_page_url
}
}
}`

//series fetch
export const getMostWatched = gql`
query GetMustWatchedSeries($page: Int!, $limit: Int!) {
  getMustWatchedSeries(page: $page, limit: $limit) {
     meta {
    total
    }
    data {
    id
    title
    image
    cover
    free
      label
    eposides_count
    published
    createdAt
    eposides {
        id
        label
      }
    }
  }
}
`

export const getSeries = gql`
query Query($page: Int!, $limit: Int!) {
getSeries(page: $page, limit: $limit) {
    meta {
    total
    per_page
    current_page
    last_page
    first_page
    first_page_url
    last_page_url
    next_page_url
    previous_page_url

    }
    data {
    id
    title
    image
    cover
    eposides_count
    published
    createdAt
      label
    eposides {
        id
        label
        free
      }
    }
}
}
`
export const getHomeSeries = gql`
query GetMustWatchedSeries($page: Int!, $limit: Int!) {
  newSeries(page: $page, limit: $limit) {
    data {
    id
    title
    image
    cover
    eposides_count
    published
    createdAt
      label
    eposides {
        id
        label
      }
    }
    meta {
    total
    per_page
    current_page
    last_page
    first_page
    first_page_url
    last_page_url
    next_page_url
    previous_page_url

    }
  }
  
  }

`
export const getSerie = gql`
query Query($getSerieId: ID!) {
         getSerie(id: $getSerieId) {
             id
             title
             image
             cover
             desc
              logo
             view_count
             published
             eposides_count
             createdAt
          label
             genre {
             title
             desc
             }
              tags {
      id
      name
    }
             eposides {
             id
             title
             label
             free
             image
             order
             season
             video_url
             video_type
             published
             }
         }
         }`