<template>
  <div
    class="relative rounded-lg w-full shadow-lg mx-2 md:max-h-[26rem] m-2 max-h-72 overflow-hidden hover:scale-105 transition duration-400 ease-in-out"
    style="height: 100%"
  >
    <div class="absolute right-5 md:right-6 top-2 hidden">
      <div class="flex justify-between space-between h-7">
        <div
          class="hidden nowrap rounded-sm text-center mt-2 mr-1 text-[11px] px-3 md:inline font-normal bg-orange-500 md:text-md text-white"
          style="line-height: 1.2rem"
        >
          {{ formateSyntax(item.eposides_count) }}
        </div>
        <div
          class="md:hidden nowrap rounded-sm text-center mt-2 mr-1 text-[11px] px-3 inline font-normal bg-orange-500 md:text-md text-white"
          style="line-height: 1.2rem"
        >
          {{ item.eposides_count }}{{ "ح" }}
        </div>
      </div>
    </div>
    <router-link :to="`/watch/now/${item.id}`" class="">
      <img
        ref="image"
        class="rounded-lg object-cover w-full h-full shadow-lg z-40"
        :src="defaultImage"
        :data-src="image(item)"
        width="100"
        height="100"
        :alt="item.title"
        style="width: 96%"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      s3: "https://d1p7gwkjs434q8.cloudfront.net/series/",
      defaultImage: require("@/assets/placeholder.webp"),
      observer: null,
    };
  },
  computed: {},
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust as needed
    });

    this.observer.observe(this.$refs.image);
  },
  methods: {
    checkLable(item) {
      if (item.label && item.label !== "null") {
        return item.label;
      }
      return false;
    },
    formateSyntax(count) {
      if (count == 1) {
        return "حلقة واحدة";
      } else if (count == 2) {
        return "حلقتان";
      } else if (count > 2 && count < 11) {
        return `${count} حلقات`;
      } else {
        return `${count} حلقة`;
      }
    },
    image(item) {
      if (item.image) {
        return this.s3 + item.image;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "default.webp");
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src; // Add this line to set the src attribute
          this.observer.unobserve(img);
        }
      });
    },
  },
};
</script>
