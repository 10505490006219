import BrowsChannels from '@/views/brows/BrowsChannels.vue'
import LiveView from '@/views/brows/LiveView.vue'
import SearchResults from '@/views/brows/SearchResults.vue'
import CatDetails from '@/views/brows/CatDetails.vue'

export const browsRoutes = [
 
  {
    path: '/brows/channels',
    name: 'brows.channels',
    component: BrowsChannels,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/brows/live/:id',
    name: 'brows.live',
    component: LiveView,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/brows/search/:query',
    name: 'results',
    component: SearchResults,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/categories/show/:id',
    name: 'categories.show',
    component: CatDetails,
    meta: {
      footer: true,
      auth: false
    }
  }
]