<template>
  <div
    class="rounded-lg md:h-[200px] my-4 px-2 lg:w-1/4  md:w-2/4 w-4/4 relative cursor-pointer group  hover:scale-105"
    tabindex="0"
    @keydown.enter="$router.push({ name: 'watch', params: { id: item.id } })"
    @click="$router.push({ name: 'watch', params: { id: item.id } })"
  >
    <!-- Overlay: Visible on hover or focus -->
   

    <div
      class="absolute bottom-0 inset-x-0 "
    >
    <div
        class="absolute bottom-0 inset-x-0 h-64 bottom-shadow-half text-white"
      ></div>
      <!-- Title -->
      <div class="absolute bottom-6 right-6">
        <p class="text-white text-right text-xl font-medium">
          {{ item.title }}
        </p>
      </div>

      <!-- Index -->
      <div class="absolute bottom-6 left-6" v-show="index >= 0">
        <p class="text-white text-right text-7xl font-medium">
          {{ index < 9 ? `0${index + 1}` : index + 1 }}
        </p>
      </div>
    </div>

    <!-- Image -->
    <img
      ref="image"
      class="rounded-lg w-full h-full object-cover"
      :src="defaultImage"
      :data-src="imageData(item.image)"
      :alt="item.title"
    />
  </div>
</template>

<script>
import { S3_BASE_URL } from "@/constants";

export default {
  components: {},
  props: ["item", "index"],
  data() {
    return {
      type: "eposides",
      S3_BASE_URL: S3_BASE_URL,
      defaultImage: require("@/assets/placeholder.webp"),
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    this.observer.observe(this.$refs.image);
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
    imageData(image) {
      return `${this.S3_BASE_URL}${this.type}/${image}`;
    },
  },
};
</script>


<style scoped>
.swiper {
  width: 100%;
  height: 308px;
}

@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 168px;
  }

  /* Hide navigation buttons on non-desktop screens */
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 480px !important;
  height: 100%;
  height: 100%;
}

@media only screen and (max-width: 960px) {
  .swiper-slide {
    text-align: center;
    width: 240px !important;
    height: 100%;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: fill !important;
}

/* Navigation buttons styles */
.swiper-button-prev,
.swiper-button-next {
  color: #FFF;
  /* Change the color to suit your design */
}

/* Navigation buttons styles with overlay */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 45%;
  z-index: 99999;
  font-size: 20px !important;
  width: 80px;
  /* Width of the arrow button */
  height: 80px;
  /* Height of the arrow button */
  margin-top: -25px;
  /* Half of the height to center vertically */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  color: #fff;
  /* White arrow color */
  border-radius: 50%;
  /* Circular shape */

}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}
</style>
