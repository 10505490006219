import gql from "graphql-tag";

export const collections = gql`
query GetCollections {
  getCollections {
    data {
      id
      name
      published
      layout
      order
      background
      image
      description
      createdAt
      updatedAt
      series {
         id
        title
        image
        cover
        eposides_count
        view_count
        published
        eposides_count
        createdAt
        label
      }
    }
  }
}
`
export const getHomeGenres = gql`
query getHomeGenres {
  getHomeGenres {
    id
    title
    eposides {
      id
      serie_id
      title
      slug
      image
      order
      season
      video_url
      free
      label
      video_type
      views_count
      published
      createdAt
      updatedAt
      free label
      serie {
        title
      
      }
      
    }
  }
}

`

export const getMostWatchedEps = gql`
query($input: mostWatchedEposidesInput)  {
  getMostWatchedEposides(input: $input) {
    data {
      id
      serie_id
      title
      slug
      image
      order
        label
        free
      published
      serie {
        title
      }
    
    }
  }
}
`
