<template>
  <div class="md:hidden mt-2">
    <search-mobile :show="show" @close="close" />
    <install-app class="" />
    <nav class="footer_nav footer_nav--icons px-6" dir="rtl">
      <ul class="flex justify-between nowrap">
        <li>
          <router-link to="/">
            <svg class="icon icon-home" viewBox="0 0 24 24" width="26" height="26">
              <path
                fill="currentColor"
                d="M21.6 8.2l-9-7c-0.4-0.3-0.9-0.3-1.2 0l-9 7c-0.3 0.2-0.4 0.5-0.4 0.8v11c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-11c0-0.3-0.1-0.6-0.4-0.8zM14 21h-4v-8h4v8zM20 20c0 0.6-0.4 1-1 1h-3v-9c0-0.6-0.4-1-1-1h-6c-0.6 0-1 0.4-1 1v9h-3c-0.6 0-1-0.4-1-1v-10.5l8-6.2 8 6.2v10.5z"
              ></path>
            </svg>
            <span>الرئيسية</span>
          </router-link>
        </li>

        <li>
          <router-link to="/brows/channels">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>

            <span>القنوات</span>
          </router-link>
        </li>
        <li>
          <a class="cursor" @click="show = true" href="#">
            <svg
              @click="show = true"
              class="icon icon-search cursor"
              viewBox="0 0 24 24"
              width="26"
              height="26"
            >
              <path
                fill="currentColor"
                d="M21.7 20.3l-3.7-3.7c1.2-1.5 2-3.5 2-5.6 0-5-4-9-9-9s-9 4-9 9c0 5 4 9 9 9 2.1 0 4.1-0.7 5.6-2l3.7 3.7c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4zM4 11c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-0.8 3.7-2 4.9 0 0 0 0 0 0s0 0 0 0c-1.3 1.3-3 2-4.9 2-4 0.1-7.1-3-7.1-6.9z"
              ></path>
            </svg>
            <span>بحث</span>
          </a>
        </li>
        <li v-if="isActive">
          <router-link to="/auth/profile" >
            <svg class="icon icon-login" viewBox="0 0 24 24" width="26" height="26">
              <g fill="currentColor">
                <path
                  d="M16 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z"
                ></path>
                <path
                  d="M12 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM12 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
                ></path>
              </g>
            </svg>
            <span>البروفايل</span>
          </router-link>
        </li>
        <li v-else @click="$router.push('/auth/login')">
          <a href="">
            <svg
              @click="$router.push('/auth/login')"
              class="icon icon-login cursor-pointer"
              viewBox="0 0 24 24"
              width="26"
              height="26"
            >
              <g fill="#FFF">
                <path
                  d="M16 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z"
                ></path>
                <path
                  d="M12 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM12 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
                ></path>
              </g>
            </svg>
            <span>دخول</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import SearchMobile from "./SearchMobile.vue";
import { ISACTIVE } from "@/constants";
import { mapActions } from 'pinia';
import { useToast } from "vue-toastification";
import {useAuthStore} from "@/stores/auth"
import InstallApp from "@/components/InstallApp"
export default {
  components: { SearchMobile, InstallApp },
  data() {
    return {
      show: false,
      scrolledToBottom: false,
      bottom: "bottom:105px !important",
      top: "bottom:30px !important",
      toast:useToast(),
      user: false,
      isActive: false,
      isDone: false,
    };
  },
  mounted() {
    this.checkIsActiveAction();
  },
  methods: {
    close() {
      return (this.show = false);
    },
    redirectAction() {

        this.$router.push('/user/subscriptions')

    },
    async checkIsActiveAction() {
      return (this.isActive = localStorage.getItem(ISACTIVE))
    },
    ...mapActions(useAuthStore, ['manageAccount']),
    async manageAcountAction() {
      await this.manageAccount().then(response => {
        this.redirectUser(response)
      })
    },// monthly starting form 1.5 Dollar //6 months  save 10% yearly save 12%
    redirectUser(response) {
      if (response.data.success) {
        this.window.location.href = response.data.url
      }
    },
    logout() {
      try{
        this.logoutAction().then((response)=>{
          if(response.data.revokeAccessToken.success){
            this.isActive = false
            this.toast.info('تم تسجيل الخروج')
            this.$router.push({ name: 'home' })
          }
        })
      }catch(error){
        console.log(error)
      }
        
        
    }

  }, //end of methods
};
</script>

<style>
.footer_nav {
  position: sticky;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 320px;
  max-width: 620px;
  height: 55px;
  border-radius: 2px;
  margin: 0 auto;
}

.footer_nav--icons ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer_nav--icons ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_nav--icons ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 11px;
  transition: color 0.3s ease;
}

.footer_nav--icons ul li a:hover,
.footer_nav--icons ul li a:active {
  color: #9d2890;
}

.footer_nav--icons ul li svg {
  margin-bottom: 4px;
}
</style>
