import gql from "graphql-tag";


export const getEposides = gql`
query Query($page: Int!, $limit: Int!) {
getEposides(page: $page, limit: $limit) {
data {
        id
        serie_id
        title
        published
        slug
        image
        order
        video_url
        video_type
        free
        label
        serie {
        id
        title
        image
        eposides_count
        createdAt
        label
        genre {
        title
        id
        }
        }

        }
        meta {
       
        next_page_url
      
        }
        }
        }
`
//filtters
export const getFiltter = gql`
query Query($page: Int!, $limit: Int!, $text: String, $genreId: String, $type: String, $order: String, $orderBy: String) {
  filters(page: $page, limit: $limit, text: $text, genreId: $genreId, type: $type, order: $order, orderBy: $orderBy) {
    data {
    
        ... on Eposide {
        id
        serie_id
        title
        slug
        image
        order
        video_url
        video_type
        free
        label
        published
        free
        label
        serie {
        id
        title
        image
        eposides_count
        label
        createdAt
        genre {
        title
        id
        }
        }
        }
        }
        meta {
       
        next_page_url
       
        }
        }
        }
`

//single eposide

export const getEposide = gql`query Query($getEposideId: ID!) {
  getEposide(id: $getEposideId) {
            id
            title
            image
            video_url
            video_type
            image_full_url
            free 
            label
            published
            createdAt
            label
            free
           embedded_code
            serie {
            id
            title
            desc
            image
            eposides_count
            createdAt
            tags {
                id
                name
              }
            genre {
              title
              id
              }
            eposides {
            id
            serie_id
            title
            image
            order
            free
            label
            video_url
            video_type
            published
            embedded_code
              video_qualities {
                quality
                url
                default
              }
            }
            }
    video_qualities {
      quality
      url
      default
    }
  }
 }
`