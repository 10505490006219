<template>
  <div>
    <svg width="120" height="41" viewBox="0 0 120 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.87062 0.0870441C9.50392 0.0870441 9.14162 0.0870441 8.77589 0.0890441C8.46974 0.0910441 8.16603 0.0968541 7.85689 0.101744C7.18542 0.109649 6.51553 0.168723 5.85304 0.278454C5.19146 0.390577 4.55061 0.60196 3.95216 0.905454C3.35444 1.21152 2.80828 1.60921 2.33351 2.08411C1.85624 2.55768 1.4584 3.10506 1.15529 3.7052C0.851338 4.30415 0.640578 4.94598 0.530288 5.60852C0.418948 6.27023 0.359036 6.93956 0.351088 7.61052C0.341808 7.91712 0.340827 8.22474 0.335938 8.53138V31.6456C0.340827 31.9561 0.341808 32.2569 0.351088 32.5675C0.359038 33.2385 0.418951 33.9078 0.530288 34.5694C0.640274 35.2324 0.851046 35.8746 1.15529 36.4737C1.45826 37.0719 1.85616 37.6171 2.33351 38.088C2.80648 38.5651 3.35299 38.963 3.95216 39.2667C4.55061 39.571 5.19139 39.7837 5.85304 39.8975C6.51566 40.0064 7.18549 40.0655 7.85694 40.0743C8.16603 40.0811 8.46974 40.085 8.77594 40.085C9.14161 40.087 9.50394 40.087 9.87062 40.087H110.471C110.83 40.087 111.195 40.087 111.555 40.085C111.859 40.085 112.172 40.0811 112.477 40.0743C113.147 40.066 113.815 40.0069 114.477 39.8975C115.14 39.7829 115.783 39.5703 116.385 39.2667C116.983 38.9628 117.529 38.5649 118.002 38.088C118.478 37.6153 118.877 37.0705 119.184 36.4737C119.486 35.8741 119.694 35.232 119.803 34.5694C119.914 33.9077 119.976 33.2385 119.988 32.5675C119.992 32.2569 119.992 31.9561 119.992 31.6456C120 31.2823 120 30.921 120 30.5518V9.62317C120 9.25696 120 8.89368 119.992 8.53138C119.992 8.22474 119.992 7.91712 119.988 7.61048C119.976 6.93947 119.914 6.27028 119.803 5.60848C119.694 4.94633 119.485 4.30455 119.184 3.70516C118.566 2.50224 117.588 1.52307 116.385 0.905364C115.783 0.602612 115.14 0.391285 114.477 0.278364C113.815 0.168147 113.147 0.109052 112.477 0.101604C112.172 0.0967241 111.859 0.0908641 111.555 0.0889141C111.195 0.0869141 110.83 0.0869141 110.471 0.0869141" fill="#A6A6A6"/>
<path d="M111.55 0.961914L112.463 0.974614C113.086 0.982013 113.707 1.03636 114.321 1.1372C114.902 1.23589 115.466 1.42053 115.992 1.68505C117.03 2.2199 117.875 3.06608 118.407 4.10498C118.668 4.62449 118.848 5.18042 118.942 5.7539C119.046 6.37791 119.104 7.00865 119.116 7.64111C119.119 7.92431 119.119 8.22851 119.119 8.53125C119.127 8.90625 119.127 9.26318 119.127 9.62304V30.5517C119.127 30.915 119.127 31.2695 119.119 31.6269C119.119 31.9521 119.119 32.25 119.115 32.5566C119.104 33.1778 119.046 33.7973 118.944 34.4101C118.851 34.9912 118.669 35.5544 118.404 36.0801C118.141 36.5925 117.798 37.0602 117.389 37.4658C116.98 37.8796 116.508 38.2249 115.989 38.4883C115.464 38.7543 114.902 38.9397 114.321 39.0381C113.703 39.1391 113.078 39.1936 112.452 39.2012C112.159 39.208 111.853 39.2119 111.555 39.2119L110.471 39.2139L8.78081 39.2119C8.47613 39.2119 8.17881 39.208 7.87652 39.2012C7.25031 39.1931 6.62555 39.1385 6.00738 39.0381C5.43097 38.9388 4.87259 38.7542 4.35064 38.4902C3.83349 38.2284 3.3618 37.8852 2.95364 37.4736C2.53958 37.0669 2.19486 36.5952 1.93313 36.0771C1.66852 35.5557 1.4854 34.9968 1.39013 34.4199C1.28726 33.8 1.2316 33.1732 1.22363 32.5449C1.21729 32.334 1.20898 31.6318 1.20898 31.6318V8.53125C1.20898 8.53125 1.21783 7.83984 1.22368 7.63671C1.23131 7.0094 1.28665 6.38357 1.38921 5.76464C1.48465 5.18616 1.66792 4.62566 1.93267 4.10254C2.19343 3.58484 2.53624 3.11274 2.94781 2.70454C3.35892 2.2925 3.83212 1.9475 4.35015 1.68212C4.8709 1.41901 5.42823 1.23565 6.00347 1.13818C6.62367 1.03675 7.2506 0.98191 7.87898 0.974124L8.7813 0.961914" fill="black"/>
<path d="M107.595 20.3882C107.606 19.5536 107.828 18.7352 108.24 18.0092C108.652 17.2833 109.241 16.6733 109.952 16.2362C109.5 15.591 108.904 15.0601 108.211 14.6855C107.518 14.311 106.748 14.1031 105.961 14.0785C104.281 13.9022 102.653 15.0833 101.798 15.0833C100.925 15.0833 99.6079 14.096 98.1892 14.1252C97.2715 14.1548 96.3771 14.4217 95.5933 14.8997C94.8094 15.3778 94.1627 16.0507 93.7162 16.853C91.7822 20.2015 93.2248 25.1225 95.0774 27.8291C96.0043 29.1545 97.0876 30.6349 98.5051 30.5824C99.8921 30.5249 100.41 29.6979 102.084 29.6979C103.743 29.6979 104.229 30.5824 105.675 30.549C107.164 30.5249 108.102 29.2178 108.996 27.8799C109.662 26.9355 110.174 25.8918 110.514 24.7874C109.65 24.4217 108.912 23.8095 108.393 23.0272C107.874 22.2449 107.596 21.3271 107.595 20.3882Z" fill="white"/>
<path d="M104.864 12.2982C105.675 11.324 106.075 10.0718 105.978 8.80762C104.738 8.93784 103.593 9.5304 102.771 10.4672C102.369 10.9249 102.061 11.4573 101.864 12.0341C101.668 12.6109 101.587 13.2207 101.627 13.8286C102.247 13.835 102.861 13.7006 103.421 13.4355C103.982 13.1704 104.475 12.7816 104.864 12.2982Z" fill="white"/>
<path d="M17.5342 27.2266H12.8008L11.6641 30.583H9.65918L14.1426 18.165H16.2256L20.709 30.583H18.6699L17.5342 27.2266ZM13.291 25.6778H17.043L15.1934 20.2305H15.1416L13.291 25.6778Z" fill="white"/>
<path d="M30.3916 26.0564C30.3916 28.8698 28.8857 30.6774 26.6133 30.6774C26.0376 30.7076 25.4651 30.575 24.9613 30.2948C24.4575 30.0147 24.0428 29.5983 23.7647 29.0934H23.7217V33.5778H21.8633V21.529H23.6621V23.0349H23.6963C23.9872 22.5324 24.4089 22.1182 24.9166 21.8364C25.4242 21.5545 25.9988 21.4156 26.5791 21.4343C28.877 21.4343 30.3916 23.2507 30.3916 26.0564ZM28.4814 26.0564C28.4814 24.2234 27.5342 23.0183 26.0889 23.0183C24.6689 23.0183 23.7139 24.2487 23.7139 26.0564C23.7139 27.8806 24.6689 29.1023 26.0889 29.1023C27.5342 29.1023 28.4814 27.906 28.4814 26.0564Z" fill="white"/>
<path d="M40.3565 26.0564C40.3565 28.8698 38.8506 30.6775 36.5782 30.6775C36.0025 30.7076 35.43 30.575 34.9262 30.2948C34.4224 30.0147 34.0077 29.5983 33.7295 29.0935H33.6865V33.5778H31.8281V21.529H33.627V23.0349H33.6611C33.9521 22.5324 34.3738 22.1182 34.8814 21.8364C35.3891 21.5545 35.9636 21.4156 36.5439 21.4343C38.8418 21.4343 40.3565 23.2507 40.3565 26.0564ZM38.4463 26.0564C38.4463 24.2234 37.4991 23.0183 36.0538 23.0183C34.6338 23.0183 33.6788 24.2487 33.6788 26.0564C33.6788 27.8806 34.6338 29.1023 36.0538 29.1023C37.4991 29.1023 38.4463 27.906 38.4463 26.0564Z" fill="white"/>
<path d="M46.9424 27.1231C47.0801 28.3545 48.2764 29.1631 49.9111 29.1631C51.4776 29.1631 52.6045 28.3545 52.6045 27.2441C52.6045 26.2802 51.9248 25.7031 50.3154 25.3076L48.7061 24.9199C46.4258 24.3691 45.3672 23.3027 45.3672 21.5723C45.3672 19.4297 47.2344 17.958 49.8858 17.958C52.5098 17.958 54.3086 19.4297 54.3692 21.5723H52.4932C52.3809 20.333 51.3564 19.585 49.8594 19.585C48.3623 19.585 47.3379 20.3418 47.3379 21.4434C47.3379 22.3213 47.9922 22.8379 49.5928 23.2334L50.9609 23.5693C53.5088 24.1718 54.5674 25.1953 54.5674 27.0117C54.5674 29.3349 52.7168 30.79 49.7734 30.79C47.0195 30.79 45.1602 29.3691 45.04 27.123L46.9424 27.1231Z" fill="white"/>
<path d="M58.5781 19.3867V21.5293H60.2998V23.001H58.5781V27.9922C58.5781 28.7676 58.9228 29.1289 59.6796 29.1289C59.884 29.1253 60.0881 29.111 60.291 29.0859V30.5488C59.9507 30.6124 59.6048 30.6412 59.2587 30.6347C57.4257 30.6347 56.7109 29.9463 56.7109 28.1904V23.001H55.3945V21.5293H56.7109V19.3867H58.5781Z" fill="white"/>
<path d="M61.2969 26.0566C61.2969 23.208 62.9746 21.418 65.5908 21.418C68.2158 21.418 69.8857 23.208 69.8857 26.0566C69.8857 28.9131 68.2246 30.6953 65.5908 30.6953C62.958 30.6953 61.2969 28.9131 61.2969 26.0566ZM67.9922 26.0566C67.9922 24.1025 67.0967 22.9492 65.5908 22.9492C64.085 22.9492 63.1904 24.1113 63.1904 26.0566C63.1904 28.0185 64.085 29.1631 65.5908 29.1631C67.0967 29.1631 67.9922 28.0185 67.9922 26.0566Z" fill="white"/>
<path d="M71.418 21.5289H73.1904V23.0699H73.2334C73.3534 22.5886 73.6353 22.1633 74.0319 21.8654C74.4285 21.5675 74.9155 21.4153 75.4112 21.4342C75.6253 21.4334 75.8389 21.4567 76.0479 21.5035V23.2418C75.7775 23.1592 75.4955 23.1213 75.2129 23.1295C74.9429 23.1186 74.6738 23.1662 74.4239 23.269C74.1741 23.3719 73.9495 23.5277 73.7655 23.7256C73.5815 23.9234 73.4425 24.1588 73.3581 24.4155C73.2737 24.6721 73.2458 24.944 73.2764 25.2125V30.5826H71.418L71.418 21.5289Z" fill="white"/>
<path d="M84.6162 27.9238C84.3662 29.5674 82.7656 30.6953 80.7178 30.6953C78.084 30.6953 76.4492 28.9307 76.4492 26.0996C76.4492 23.2598 78.0928 21.418 80.6397 21.418C83.1445 21.418 84.7197 23.1387 84.7197 25.8838V26.5205H78.3252V26.6328C78.2957 26.966 78.3374 27.3017 78.4476 27.6175C78.5578 27.9334 78.7339 28.2221 78.9642 28.4647C79.1946 28.7072 79.4739 28.898 79.7837 29.0243C80.0934 29.1506 80.4265 29.2096 80.7607 29.1973C81.1999 29.2384 81.6406 29.1367 82.0173 28.9073C82.394 28.6778 82.6866 28.3329 82.8516 27.9238L84.6162 27.9238ZM78.334 25.2217H82.8604C82.8763 24.9362 82.8358 24.6504 82.7412 24.3805C82.6466 24.1107 82.4999 23.8621 82.3092 23.649C82.1186 23.4359 81.8879 23.2624 81.6302 23.1385C81.3725 23.0146 81.093 22.9426 80.8075 22.9267C80.7516 22.9236 80.6956 22.9226 80.6397 22.9238C80.3387 22.922 80.0403 22.9795 79.7615 23.0931C79.4828 23.2066 79.2291 23.3739 79.015 23.5855C78.8009 23.797 78.6306 24.0487 78.5137 24.3261C78.3969 24.6034 78.3358 24.9011 78.334 25.2021C78.334 25.2087 78.334 25.2152 78.334 25.2217Z" fill="white"/>
<path d="M54.1294 16.1705H53.4761C52.2041 16.1705 50.8623 15.9093 50.8623 14.1491C50.9476 13.3575 51.1289 12.5794 51.4023 11.8317L52.2475 12.0582L52.1254 12.4503C51.9446 13.0018 51.8249 13.5714 51.7685 14.149C51.7685 15.36 52.9272 15.36 53.7114 15.36H54.2602C54.6523 15.36 55.1923 15.2032 55.1923 14.7067V11.2653H56.1249V13.8444C56.3258 13.9296 56.5425 13.971 56.7607 13.9659H57.083V14.7677H56.7607C56.5453 14.7662 56.3312 14.734 56.1249 14.672V14.794C56.125 15.2467 55.7764 16.1705 54.1294 16.1705ZM53.5981 10.3243C53.4885 10.3185 53.383 10.2807 53.2946 10.2157C53.2062 10.1506 53.1387 10.0611 53.1006 9.95817C53.0624 9.85524 53.0552 9.7434 53.0799 9.63643C53.1045 9.52945 53.1599 9.43203 53.2392 9.35616C53.3186 9.28029 53.4184 9.22929 53.5263 9.20946C53.6343 9.18962 53.7457 9.20181 53.8468 9.24452C53.948 9.28723 54.0344 9.3586 54.0954 9.44983C54.1565 9.54106 54.1895 9.64816 54.1904 9.75793C54.1944 9.90448 54.14 10.0466 54.0393 10.1531C53.9385 10.2595 53.7995 10.3216 53.653 10.3256C53.6347 10.3261 53.6164 10.3257 53.5981 10.3243Z" fill="white"/>
<path d="M58.1352 13.9652C58.0279 13.5826 57.9778 13.1862 57.9868 12.789C57.9593 12.5262 57.988 12.2606 58.071 12.0098C58.154 11.7591 58.2894 11.5288 58.4682 11.3343C58.647 11.1399 58.8651 10.9857 59.1081 10.882C59.351 10.7782 59.6133 10.7274 59.8774 10.7328C61.1147 10.7328 61.5942 11.7436 61.5942 12.9462C61.5942 13.8349 61.2802 15.037 60.0693 15.037C59.67 14.9985 59.2775 14.9077 58.9018 14.767H56.9238V13.9653L58.1352 13.9652ZM59.0761 14.0004C59.3721 14.1256 59.6874 14.1993 60.0083 14.2182C60.5483 14.2182 60.6967 13.5297 60.6967 12.9198C60.6967 12.3363 60.5312 11.4999 59.8252 11.4999C59.1894 11.4999 58.9106 12.0922 58.9106 12.8417C58.8988 13.2346 58.9548 13.6266 59.0761 14.0004Z" fill="white"/>
<path d="M67.7432 16.1699H67.0898C65.8174 16.1699 64.4756 15.9087 64.4756 14.1484C64.5611 13.357 64.7425 12.5788 65.0156 11.8311L65.8613 12.0576L65.7392 12.4497C65.5582 13.0012 65.4384 13.5708 65.3818 14.1484C65.3818 15.3594 66.541 15.3594 67.3252 15.3594H67.874C68.2656 15.3594 68.8056 15.2026 68.8056 14.706V8.89453H69.7382V13.8438C69.9391 13.9289 70.1558 13.9704 70.374 13.9653H70.6963V14.7671H70.374C70.1586 14.7655 69.9445 14.7333 69.7382 14.6714V14.7934C69.7383 15.2461 69.3897 16.1699 67.7432 16.1699Z" fill="white"/>
<path d="M73.831 10.7773V13.2256C73.831 14.2622 73.2119 14.7676 71.9834 14.7676H70.5371V13.9658H71.9492C72.6894 13.9658 72.8896 13.6436 72.8896 13.0947V10.7773H73.831ZM71.496 16.7192C71.3864 16.7133 71.281 16.6755 71.1926 16.6104C71.1043 16.5452 71.0369 16.4557 70.9988 16.3528C70.9607 16.2498 70.9536 16.138 70.9783 16.031C71.003 15.9241 71.0584 15.8267 71.1377 15.7509C71.2171 15.6751 71.3169 15.6241 71.4249 15.6043C71.5328 15.5845 71.6442 15.5967 71.7453 15.6394C71.8464 15.6822 71.9328 15.7535 71.9938 15.8448C72.0549 15.936 72.0879 16.0431 72.0888 16.1528C72.0909 16.23 72.0768 16.3068 72.0474 16.3783C72.018 16.4497 71.974 16.5142 71.9181 16.5676C71.8623 16.6209 71.7959 16.662 71.7232 16.6881C71.6505 16.7143 71.5731 16.7248 71.496 16.7192ZM72.9599 16.7192C72.8503 16.7135 72.7447 16.6758 72.6562 16.6108C72.5678 16.5458 72.5002 16.4563 72.462 16.3534C72.4238 16.2505 72.4165 16.1386 72.4411 16.0316C72.4657 15.9246 72.5211 15.8271 72.6004 15.7512C72.6797 15.6753 72.7795 15.6242 72.8875 15.6044C72.9954 15.5845 73.1069 15.5967 73.208 15.6394C73.3092 15.6821 73.3956 15.7534 73.4567 15.8447C73.5178 15.9359 73.5508 16.043 73.5517 16.1528C73.5557 16.2994 73.5013 16.4415 73.4005 16.5479C73.2997 16.6544 73.1608 16.7164 73.0142 16.7204C72.9961 16.7209 72.978 16.7205 72.9599 16.7192Z" fill="white"/>
<path d="M74.5264 16.0739C74.6592 16.0902 74.7929 16.0988 74.9267 16.0997C75.6943 16.0997 76.0771 15.6554 76.0771 14.7579V11.2555H77.0185V13.965H77.8808V14.7667H77.0098C77.0201 15.3607 76.8052 15.9366 76.4082 16.3786C75.9847 16.7329 75.4442 16.9162 74.8926 16.8927C74.6943 16.8896 74.4964 16.8751 74.2998 16.8493L74.5264 16.0739ZM76.5039 10.4103C76.3943 10.4044 76.2888 10.3665 76.2005 10.3014C76.1121 10.2363 76.0447 10.1468 76.0067 10.0438C75.9686 9.94088 75.9614 9.82904 75.9861 9.7221C76.0108 9.61515 76.0662 9.51777 76.1456 9.44194C76.225 9.36612 76.3248 9.31516 76.4327 9.29536C76.5407 9.27556 76.6521 9.28778 76.7532 9.3305C76.8543 9.37323 76.9407 9.4446 77.0017 9.53583C77.0627 9.62705 77.0957 9.73413 77.0967 9.84389C77.0988 9.92112 77.0848 9.99795 77.0554 10.0694C77.026 10.1409 76.9819 10.2054 76.9261 10.2587C76.8702 10.3121 76.8038 10.3532 76.7311 10.3793C76.6583 10.4054 76.5809 10.416 76.5039 10.4103Z" fill="white"/>
<path d="M80.9726 14.7672C80.5969 14.7556 80.2296 14.6539 79.9013 14.4708C79.4503 14.7123 78.938 14.8154 78.4287 14.7672H77.7227V13.9655H78.4815C79.1953 13.9655 79.335 13.8522 79.335 13.3297V10.777H80.2676V13.8087C80.4875 13.9144 80.7287 13.9681 80.9727 13.9655H81.5567V14.7672L80.9726 14.7672ZM79.7617 9.90541C79.6521 9.8995 79.5466 9.86165 79.4583 9.79653C79.3699 9.73141 79.3025 9.64187 79.2644 9.53893C79.2264 9.43599 79.2192 9.32416 79.2439 9.21722C79.2686 9.11027 79.324 9.01289 79.4034 8.93706C79.4827 8.86123 79.5825 8.81028 79.6905 8.79048C79.7985 8.77068 79.9098 8.78289 80.0109 8.82562C80.112 8.86835 80.1984 8.93972 80.2595 9.03094C80.3205 9.12216 80.3535 9.22925 80.3544 9.339C80.3566 9.41624 80.3425 9.49307 80.3132 9.56453C80.2838 9.63599 80.2397 9.70049 80.1839 9.75387C80.128 9.80724 80.0616 9.84831 79.9888 9.87442C79.9161 9.90052 79.8387 9.91108 79.7617 9.90541Z" fill="white"/>
<path d="M83.7861 10.777V13.2252C83.7861 14.2619 83.168 14.7672 81.9394 14.7672H81.3984V13.9655H81.9043C82.6445 13.9655 82.8447 13.6432 82.8447 13.0944V10.777H83.7861ZM82.4004 9.90541C82.2908 9.8995 82.1853 9.86165 82.097 9.79653C82.0086 9.73141 81.9412 9.64187 81.9031 9.53893C81.8651 9.43599 81.8579 9.32416 81.8826 9.21722C81.9073 9.11027 81.9627 9.01289 82.0421 8.93706C82.1214 8.86123 82.2212 8.81028 82.3292 8.79048C82.4372 8.77068 82.5486 8.78289 82.6497 8.82562C82.7508 8.86835 82.8371 8.93972 82.8982 9.03094C82.9592 9.12216 82.9922 9.22925 82.9931 9.339C82.9953 9.41624 82.9812 9.49307 82.9519 9.56453C82.9225 9.63599 82.8784 9.70049 82.8226 9.75387C82.7667 9.80724 82.7003 9.84831 82.6275 9.87442C82.5548 9.90052 82.4774 9.91108 82.4004 9.90541ZM83.8643 9.90541C83.7547 9.8995 83.6492 9.86165 83.5608 9.79653C83.4725 9.73141 83.4051 9.64187 83.367 9.53893C83.3289 9.43599 83.3218 9.32416 83.3465 9.21722C83.3712 9.11027 83.4266 9.01289 83.506 8.93706C83.5853 8.86123 83.6851 8.81028 83.7931 8.79048C83.901 8.77068 84.0124 8.78289 84.1135 8.82562C84.2146 8.86835 84.301 8.93972 84.362 9.03094C84.4231 9.12216 84.4561 9.22925 84.457 9.339C84.4592 9.41624 84.4451 9.49307 84.4157 9.56453C84.3863 9.63599 84.3423 9.70049 84.2864 9.75387C84.2306 9.80724 84.1641 9.84831 84.0914 9.87442C84.0187 9.90052 83.9413 9.91108 83.8643 9.90541Z" fill="white"/>
</svg>

  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>