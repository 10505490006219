<template>
     <svg
      width="1600"
      height="295"
      viewBox="0 0 1200 295"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M600 -299.574C268.629 -299.574 -6.02169e-05 -166.474 -3.87924e-05 -2.28683C-1.7368e-05 161.9 268.629 295 600 295C931.371 295 1200 161.9 1200 -2.2869C1200 -166.474 931.371 -299.574 600 -299.574Z"
        fill="url(#paint0_radial_1497_5269)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1497_5269"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(600 -2.28686) rotate(-90) scale(297.287 600)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
</template>