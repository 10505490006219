<template>
  <div
    class="container mx-auto flex flex-col items-center font-Almarai relative overflow-x-hidden"
  >
    <logo-svg class="absolute" />

    <div class="flex justify-center items-center w-3/12 mt-4 h-36">
      <img src="@/assets/logo-1.webp" alt="logo" />
    </div>
    <!-- Main Content -->
    <div
      class="flex flex-col justify-center items-center gap-y-6 bg-transparent rounded-md md:mx-auto mx-2"
    >
      <h1 class="text-gray-600 text-md my-2 font-Almarai">خطوة 2 من 2</h1>
      <h1 class="md:text-2xl font-bold text-white my-2 font-Almarai">
        معلومات الدفع
      </h1>
      <!-- Email Form -->
      <div v-if="!isValidEmail">
        <form id="payment-form" dir="rtl">
          <label for="email" class="text-white">البريد الإلكتروني</label>

          <input
            type="email"
            id="email"
            name="email"
            placeholder="البريد الإلكتروني"
            required
            class="bg-[#30313d] px-6 py-2 my-3 rounded-md text-white w-full focus:ring-gray-500 focus:border-gray-500"
            v-model="userEmail"
          />

          <button
            type="button"
            class="bg-basic px-6 py-2 my-3 rounded-md text-white w-full"
            @click="AddEmail"
          >
            المتابعة
          </button>
        </form>
      </div>
      <!-- Payment Form -->
      <div>
        <form id="payment-form" @submit.prevent="handleSubmit" dir="rtl">
         

          <div id="payment-element"></div>
          <button
            type="submit"
            class="bg-basic px-6 py-2 my-3 rounded-md text-white w-full"
            :class="{ 'opacity-50': isLoading }"
            v-if="subscribeBTN"
            :disabled="isLoading"
          >
            اشترك الان
          </button>
        </form>
        <div v-if="errorMessage">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import LogoSvg from "@/components/svg/LogoSvg.vue";
import { useToast } from "vue-toastification";
import { USER } from "@/constants";
export default {
  components: { LogoSvg },
  data() {
    return {
      selectedCurrency: "aed", // Default currency
      prices: [], // List of prices fetched from the backend
      selectedPriceId: "", // Selected price ID
      stripe: null,
      elements: null, // Store the Elements instance
      clientSecret: "",
      errorMessage: "",
      subscribeBTN: false,
      selectedPlan: localStorage.getItem("selectedPlan"),
      userEmail: "", // Add userEmail data property
      toast: useToast(),
      isLoading: false,
      isValidEmail: false,
      priceData: null,
    };
  },
  async mounted() {
    // Initialize Stripe
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIIC_KEY); // Replace with your publishable key

    // Fetch prices for the default currency
    this.fetchPrices();
  },
  methods: {
    AddEmail() {
      this.isLoading = true;
      if (this.userEmail === "") {
        this.toast.error("الرجاء إدخال البريد الإلكتروني");
        return;
      }
      this.isValidEmail=true
      this.isLoading=false
      this.createpaymentIntend();
    },
    async fetchPrices() {
      // Fetch prices for the selected currency
      const response = await fetch(`https://api.nasna.tv/api/stripe/prices`);
      this.prices = await response.json();
    },
    // Pass the entire price Object from database
    async selectPrice(price) {
      this.selectedPriceId = price.id;

      this.priceData = price;
    },
    async createpaymentIntend() {
      const userId = JSON.parse(localStorage.getItem(USER)).id;

      // Fetch the clientSecret for the selected price
      const response = await fetch(
        "https://api.nasna.tv/api/stripe/create-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: this.priceData.id,
            email: this.userEmail, // Use the user's email from input

            // currency: this.selectedCurrency,
            currency: this.priceData.currency,
            userId,
            metadata: {
              user_id: userId,
              internal_plan_uuid: this.priceData.metadata.internal_plan_uuid,
            },
          }),
        }
      );

      const { clientSecret } = await response.json();
      this.clientSecret = clientSecret;

      console.log("ClientSecret from backend", clientSecret);

      const elementsAppearence = {
        theme: "night", // Use the default Stripe theme
        variables: {
          colorPrimary: "#0570de", // Customize primary color
          // colorBackground: "green",
        },
        rules: {
          ".Input": {
            border: "1px solid #ced4da", // Customize input border
            fontFamily: "Almarai, sans-serif",
          },
        },
      };
      // Initialize the Elements instance
      this.elements = this.stripe.elements({
        clientSecret,
        locale: "ar",
        appearance: elementsAppearence,
      });

      const paymentOptions = {
        fields: {
          billingDetails: {
            address: {
              country: "never", // Hides the country field
            },
            email: "auto", // Displays the email field for the user to fill
          },
        },
      };

      // Create and mount the Payment Element
      const paymentElement = this.elements.create("payment", paymentOptions);
      paymentElement.mount("#payment-element");

      this.subscribeBTN = true;
    },
    async handleSubmit() {
      this.isLoading = true;
      if (this.userEmail === "") {
        this.toast.error("الرجاء إدخال البريد الإلكتروني");
        return;
      }
      this.errorMessage = "";

      // Confirm the payment using the Elements instance
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements, // Use the stored Elements instance
        confirmParams: {
          return_url: "https://nasna.tv/auth/stripe/check-payment-intent", // replace with route on Front-End and then call this endpoint later
          payment_method_data: {
            billing_details: {
              address: {
                country: "AE", // Provide the country explicitly
              },
              email: this.userEmail, // Use the user's email from input
            },
          },
        },
      });
      this.isLoading = false;
      if (error) {
        this.errorMessage = error.message;
      }
    },
  },
  created() {
    const selectedPlan = localStorage.getItem("selectedPrice");
    let price = null;
    if (selectedPlan) {
      price = JSON.parse(localStorage.getItem("selectedPrice"));
    } else {
      console.log("No selected plan found");
    }
    if (price) {
      this.selectedPriceId = price.id;
      this.selectPrice(price);
    }
  },
};
</script>
<style scoped>
body {
  background-color: white !important;
}
li {
  color: white !important;
}
h2 {
  color: wheat !important;
}
.wrapper {
  width: 100%;
}
</style>
